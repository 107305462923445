import classNames from "classnames";
import type { ReactNode } from "react";
import { BaseClickableIcon } from "../../../../components/Icon/BaseClickableIcon.tsx";

export const RightDetailsLayout = ({
  children,
  name,
  onCloseButtonClick,
  className,
  variant = "light",
}: {
  children: ReactNode;
  name: string;
  onCloseButtonClick?: () => void;
  className?: string;
  variant?: "light" | "dark";
}) => (
  <div
    className={classNames(
      "flex-col w-[226px] border-l-025",
      className,
      variant === "dark" ? "border-l-border-inverse" : "border-l-border",
    )}
  >
    <div
      className={classNames(
        "flex-row justify-between h-[54px] items-center px-400 py-300 gap-400",
        variant === "dark"
          ? "border-b-border-inverse border-b-025"
          : "border-b-border border-b-025",
      )}
    >
      <div
        className={classNames(
          "heading-lg",
          variant === "dark" ? "text-inverse-primary" : "text-primary",
        )}
      >
        {name}
      </div>
      <BaseClickableIcon
        name="X"
        iconClassName={
          variant === "dark" ? "stroke-inverse-rest" : "stroke-primary-rest"
        }
        onClick={onCloseButtonClick}
      />
    </div>
    <div className="flex-fill flex-col overflow-y-auto">{children}</div>
  </div>
);
