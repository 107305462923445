export const RectangleHorizontal = ({
  className,
  size = 16,
}: {
  className?: string;
  size?: number;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={size}
    height={(14 / 21) * size}
    fill="none"
    viewBox="0 0 21 14"
  >
    <rect width="20" height="13" x="0.5" y="0.5" rx="1.5" />
  </svg>
);
