import { useEffect, useRef, useState } from "react";
import { z } from "zod";
import { UrlAnimation } from "../../../../../../components/Animation/UrlAnimation.tsx";
import { Form } from "../../../../../../components/Form/Form.tsx";
import { useForm } from "../../../../../../components/Form/FormContext.ts";
import { Toggle } from "../../../../../../components/Toggle/Toggle.tsx";
import { addNullInput } from "../../../../../../utils/validations.ts";
import { LearnMoreLink } from "../../../../components/LearnMoreLink.tsx";
import { useSelectedAsset } from "../../../../hooks/useSelectedAsset.ts";
import { useSelectedStylesGenerationParams } from "../../../../hooks/useSelectedStylesGenerationParams.ts";
import { editAssetMenuStore } from "../../../../stores/editAssetMenuStore.ts";
import { GenerationBar } from "../../../components/GenerationBar/GenerationBar.tsx";
import { CollapsableSettingsSectionWrapper } from "../../../components/SettingsMenu/CollapsableSettingsSectionWrapper.tsx";
import { SettingsSectionWrapper } from "../../../components/SettingsMenu/SettingsSectionWrapper.tsx";
import { SliderSection } from "../../../components/SettingsMenu/SliderSection.tsx";
import { TextSection } from "../../../components/SettingsMenu/TextSection.tsx";
import { SettingsMenuLayout } from "../../../components/SettingsMenuLayout.tsx";
import { useUpscaleImageGeneration } from "./hooks/useUpscale.ts";
import {
  UPSCALE_DEFAULT_SETTINGS,
  useUpscaleSettings,
} from "./hooks/useUpscaleSettings.ts";
import { ImaginationStrengthSection } from "./ImaginationStrengthSection.tsx";

export const UpscaleSettings = () => {
  const { mutation: upscaleMutate, isLoading: isGenerationLoading } =
    useUpscaleImageGeneration({});

  return (
    <Form
      className="flex-col flex-fill"
      schema={zUpscaleSettings}
      initialValues={{
        selectedAssetUuid: "",
        prompt: "",
        selectedStyleUuid: null,
        isSelectedImageSucceeded: false,
        areMultipleStylesSelected: false,
      }}
      onSubmit={(values) => {
        // FIXME: make the mutation async
        upscaleMutate({
          prompt: values.prompt,
          assetUuid: values.selectedAssetUuid,
        });
        return Promise.resolve(true);
      }}
    >
      <UpscaleSettingsFormContent isGenerationLoading={isGenerationLoading} />
    </Form>
  );
};

const zUpscaleSettings = z.object({
  selectedAssetUuid: z
    .string({ required_error: "Please upload an image in the canvas" })
    .uuid(),
  selectedStyleUuid: addNullInput(z.string().uuid(), "Please select a model"),
  prompt: z.string().min(1, { message: "Please enter a description" }),
  isSelectedImageSucceeded: z.boolean().refine((val) => val, {
    message: "Please wait for your image to be generated",
  }),
  areMultipleStylesSelected: z.boolean().refine((val) => !val, {
    message: "Multiple model not available with this tool",
  }),
});
type UpscaleSettingsValues = z.input<typeof zUpscaleSettings>;

export const UpscaleSettingsFormContent = ({
  isGenerationLoading,
}: {
  isGenerationLoading: boolean;
}) => {
  const { upscaleSettings, setUpscaleSettings } = useUpscaleSettings();
  const { selectedStylesGenerationParams } =
    useSelectedStylesGenerationParams();
  const { selectedAsset } = useSelectedAsset();

  const { useValue, setValues, submit, useError } =
    useForm<UpscaleSettingsValues>();
  const prompt = useValue((v) => v.prompt);
  const missingStyleError = useError((v) => v.selectedStyleUuid);
  const missingAssetError = useError((v) => v.selectedAssetUuid);
  const areMultipleStylesSelectedError = useError(
    (v) => v.areMultipleStylesSelected,
  );
  let imageNotSucceededError = useError((v) => v.isSelectedImageSucceeded);
  if (missingAssetError) imageNotSucceededError = undefined;

  useEffect(() => {
    setValues({
      selectedStyleUuid: selectedStylesGenerationParams.length
        ? selectedStylesGenerationParams[0].uuid
        : null,
      areMultipleStylesSelected: selectedStylesGenerationParams.length > 1,
    });
  }, [setValues, selectedStylesGenerationParams]);

  useEffect(() => {
    setValues({ selectedAssetUuid: selectedAsset?.uuid });
  }, [setValues, selectedAsset?.uuid]);

  useEffect(() => {
    setValues({
      isSelectedImageSucceeded: selectedAsset?.content.status === "succeeded",
    });
  }, [setValues, selectedAsset?.content.status]);

  useEffect(() => {
    setValues({ prompt: selectedAsset?.content.description ?? "" });
  }, [selectedAsset, setValues]);

  const [advancedSectionOpen, setAdvancedSectionOpen] = useState(false);
  useEffect(() => {
    if (upscaleSettings.imagination_strength_preset === "custom") {
      setAdvancedSectionOpen(true);
    }
  }, [upscaleSettings.imagination_strength_preset]);

  const previousImageUuid = useRef<string>();
  const [isWaitingForDescription, setIsWaitingForDescription] = useState(false);

  useEffect(() => {
    // XXX: the description loading state updates differently if the user selects another image.
    // To detect if the user has changed the selected image we use a ref previousImageUuid.
    // Case where the user is still on the same image:
    if (previousImageUuid.current === selectedAsset?.content.uuid) {
      if (isWaitingForDescription && selectedAsset?.content.description) {
        setValues({ prompt: selectedAsset.content.description });
        setIsWaitingForDescription(false);
      }
    }
    // Case where the user selects another image:
    else {
      previousImageUuid.current = selectedAsset?.content.uuid;
      if (selectedAsset?.content.description) {
        setValues({ prompt: selectedAsset.content.description });
        setIsWaitingForDescription(false);
      } else {
        setIsWaitingForDescription(true);
      }
    }
  }, [
    isWaitingForDescription,
    selectedAsset?.content.description,
    selectedAsset?.content.uuid,
    setValues,
  ]);

  return (
    <SettingsMenuLayout
      body={
        <div className="flex-col">
          <SettingsSectionWrapper
            name="Upscale factor"
            inline
            content={
              <div>
                <Toggle
                  leftItem="2x"
                  rightItem="4x"
                  value={
                    (upscaleSettings.scale_factor ??
                      UPSCALE_DEFAULT_SETTINGS.scale_factor) === 2
                      ? "left"
                      : "right"
                  }
                  onValueChange={(value) =>
                    setUpscaleSettings({
                      scale_factor: value === "left" ? 2 : 4,
                    })
                  }
                />
              </div>
            }
          />
          <SettingsSectionWrapper
            name="Imagination"
            content={<ImaginationStrengthSection />}
            // FIXME: Add info content
            infoContent={undefined}
          />
          <CollapsableSettingsSectionWrapper
            name="Advanced"
            open={advancedSectionOpen}
            onOpenChange={setAdvancedSectionOpen}
            content={
              <div className="flex-col">
                <SliderSection
                  min={0}
                  max={100}
                  value={Math.round(upscaleSettings.creativity * 100)}
                  sliderName="Creativity"
                  onChange={(creativity: number) =>
                    setUpscaleSettings({
                      creativity: creativity / 100,
                      imagination_strength_preset: "custom",
                    })
                  }
                  sliderInformationSection={
                    <div className="flex-col body-md-default text-primary gap-200">
                      <div>
                        <span className="body-md-semibold">Creativity</span> -
                        Allows the AI to "hallucinate" additional details,
                        achieving greater realism at the cost of moving further
                        away from the original image. Here's the Pimento’s
                        touch! But be careful: really high values can lead to
                        some pretty strange results.
                      </div>
                      <span className="body-md-semibold">
                        Recommendation:{" "}
                        {Math.round(UPSCALE_DEFAULT_SETTINGS.creativity * 100)}%
                      </span>
                      <LearnMoreLink
                        url="https://pimento.crisp.help/en/article/upscale-parameters-and-examples-1s8bhae/"
                        className="self-end"
                      />
                    </div>
                  }
                />
                <SliderSection
                  min={0}
                  max={100}
                  value={Math.round(upscaleSettings.resemblance * 100)}
                  sliderName="Similarity"
                  onChange={(resemblance: number) =>
                    setUpscaleSettings({
                      resemblance: resemblance / 100,
                      imagination_strength_preset: "custom",
                    })
                  }
                  sliderInformationSection={
                    <div className="min-h-[330px] text-primary body-md-default flex-col gap-200">
                      <div>
                        <span className="body-md-semibold">Similarity</span> -
                        Increasing this value will make the generation more
                        closely resemble the original image, but very high
                        values can result in blotches or a dirtier look. Lower
                        values give more freedom to the generation at the cost
                        of moving further away from the original image.
                      </div>
                      <span className="body-md-semibold">
                        Recommendation:{" "}
                        {Math.round(UPSCALE_DEFAULT_SETTINGS.resemblance * 100)}
                        %
                      </span>
                      <UrlAnimation url="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/092871bb-0f96-45bc-926d-c94a22fa46cc.json" />
                      <LearnMoreLink
                        url="https://pimento.crisp.help/en/article/upscale-parameters-and-examples-1s8bhae/"
                        className="mr-100 self-end"
                      />
                    </div>
                  }
                />
                <TextSection
                  title="Exclude"
                  valuePlaceholder="illustration, wonderful chilli pepper, vivid colors..."
                  value={upscaleSettings.negative_prompt}
                  onValueChange={(negativePrompt: string) =>
                    setUpscaleSettings({ negative_prompt: negativePrompt })
                  }
                />
              </div>
            }
          />
        </div>
      }
      footer={
        <GenerationBar
          isLoading={isGenerationLoading}
          onGenerate={() => {
            submit();
            editAssetMenuStore.setCurrentAssetMenuType("history");
          }}
          prompt={prompt}
          setPrompt={(newPrompt) => {
            setValues({ prompt: newPrompt });
          }}
          promptError={useError((v) => v.prompt)}
          isPromptLoading={isWaitingForDescription}
          onPromptLoadingChange={(loading) => {
            setIsWaitingForDescription(loading);
            if (!loading) setValues({ prompt: "" });
          }}
          sectionTitle="Image description"
          buttonContent="Upscale"
          extraErrors={[
            missingAssetError,
            missingStyleError,
            imageNotSucceededError,
            areMultipleStylesSelectedError,
          ].filter((error): error is string => error !== undefined)}
        />
      }
    />
  );
};
