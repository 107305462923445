import { useUser } from "../../../../hooks/useUser.ts";
import { isUserAllowedTo } from "../../../../types.ts";
import type { Style } from "../types.ts";

export const useUserCanDeleteStyle = (style: Style) => {
  const { user } = useUser();

  return (
    !!user && (!style.is_curated || isUserAllowedTo(user, "styles:delete-any"))
  );
};
