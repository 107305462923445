import { useUser } from "../../../hooks/useUser.ts";
import { isUserAllowedTo } from "../../../types.ts";
import {
  editAssetMenuStore,
  type EditAssetMenuType,
} from "../stores/editAssetMenuStore.ts";
import { AssetMenuButton } from "./AssetMenuButton.tsx";

export const EditAssetMenu = () => {
  const { user } = useUser();
  const { currentAssetMenuType } = editAssetMenuStore.useState();

  const handleOnClick = (assetMenuType: EditAssetMenuType) => {
    if (currentAssetMenuType === assetMenuType) {
      editAssetMenuStore.reset();
    } else {
      editAssetMenuStore.setCurrentAssetMenuType(assetMenuType);
    }
  };

  return (
    <div className="flex-col">
      <AssetMenuButton
        iconName="Info"
        title="Info"
        isActive={currentAssetMenuType === "info"}
        onClick={() => handleOnClick("info")}
        tooltipContent="Display asset information"
      />
      <AssetMenuButton
        iconName="Clock"
        title="History"
        isActive={currentAssetMenuType === "history"}
        onClick={() => handleOnClick("history")}
        tooltipContent="Display asset edition history"
      />
      <AssetMenuButton
        iconName="Tag"
        title="Tags"
        isActive={currentAssetMenuType === "tags"}
        onClick={() => handleOnClick("tags")}
        tooltipContent="Show and edit asset tags"
      />
      {user && isUserAllowedTo(user, "mode:debug") && (
        <AssetMenuButton
          iconName="HelpCircle"
          title="Debug"
          isActive={currentAssetMenuType === "debug"}
          onClick={() => handleOnClick("debug")}
          tooltipContent="Show debug information"
        />
      )}
    </div>
  );
};
