import { useAppMutation } from "../../../../http/useAppMutation.ts";
import { saveReturnPath } from "../../../../utils/returnPath.ts";

export const useCreateTopUpCheckoutUrl = () => {
  const {
    mutate: createTopUpCheckoutUrl,
    isLoading: isCreateTopUpCheckoutUrlLoading,
  } = useAppMutation<{ url: string | undefined }>({
    path: "payments/create-top-up-checkout-url",
    invalidate: [],
    onSuccess: (response) => {
      if (response.data.url) window.open(response.data.url, "_self");
    },
  }).mutation;

  return {
    createTopUpCheckoutUrl: ({ workspaceUuid }: { workspaceUuid: string }) => {
      saveReturnPath();
      createTopUpCheckoutUrl({ workspace_uuid: workspaceUuid });
    },
    isCreateTopUpCheckoutUrlLoading,
  };
};
