import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../components/Button/Button.tsx";
import { useUser } from "../../hooks/useUser.ts";
import { useAppMutation } from "../../http/useAppMutation.ts";
import { STYLES, WORKSPACES } from "../../routes.ts";
import { isUserAllowedTo } from "../../types.ts";

export const AdminView = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  const [targetWorkspaceUuid, setTargetWorkspaceUuid] = useState<string>();
  const [styleUuidToCopy, setStyleUuidToCopy] = useState<string>();
  const [newCopiedStyleUuid, setNewCopiedStyleUuid] = useState<string>();
  const [returnedWorkspaceUuid, setReturnWorkspaceUuid] = useState<string>();

  const copyStyle = useAppMutation({
    path: "styles/copy",
    invalidate: [],
    onSuccess: (response: {
      data: { style_uuid: string; workspace_uuid: string };
    }) => {
      setNewCopiedStyleUuid(response.data.style_uuid);
      setReturnWorkspaceUuid(response.data.workspace_uuid);
    },
    onError: () => setNewCopiedStyleUuid(undefined),
  }).mutation;

  useEffect(() => {
    if (!(user && isUserAllowedTo(user, "styles:copy"))) navigate(WORKSPACES);
  }, [user, navigate]);
  return (
    <div className="flex-fill flex-col items-center text-primary">
      <div className="mt-800 heading-2xl">Admin</div>

      <div className="flex-col gap-200 w-[300px]">
        <div className="self-center mt-800 heading-xl">Copy styles</div>
        <div className="flex-col gap-100">
          <span className="body-lg-semibold">Style UUID</span>
          <input
            type="text"
            className="p-200 rounded-100 border-025 body-md-default text-primary placeholder:text-disabled"
            placeholder="aeaeae-ffff-eeee-ffff-a1a1a1a1"
            value={styleUuidToCopy}
            onChange={(event) => {
              setStyleUuidToCopy(event.target.value);
              setNewCopiedStyleUuid(undefined);
            }}
          />
        </div>
        <div className="flex-col gap-100">
          <span className="body-lg-semibold">Target workspace UUID</span>
          <input
            type="text"
            className="p-200 rounded-100 border-025 body-md-default text-primary placeholder:text-disabled"
            placeholder="aeaeae-ffff-eeee-ffff-a1a1a1a1"
            value={targetWorkspaceUuid}
            onChange={(event) => {
              setTargetWorkspaceUuid(event.target.value);
              setNewCopiedStyleUuid(undefined);
            }}
          />
        </div>
        <Button
          disabled={!targetWorkspaceUuid || !styleUuidToCopy}
          size="md"
          loading={copyStyle.isLoading}
          onClick={() =>
            copyStyle.mutate({
              workspace_uuid: targetWorkspaceUuid,
              style_uuid: styleUuidToCopy,
            })
          }
        >
          Copy
        </Button>
        {newCopiedStyleUuid !== undefined &&
          returnedWorkspaceUuid !== undefined && (
            <Link
              to={`${WORKSPACES}/${returnedWorkspaceUuid}/${STYLES}/${newCopiedStyleUuid}`}
              className="self-center text-primary underline hover:text-secondary"
            >
              Go to new copied style
            </Link>
          )}
      </div>
    </div>
  );
};
