import { createStore } from "../../../utils/createStore.ts";

export type EditAssetMenuType = "info" | "tags" | "debug" | "history";

export const editAssetMenuStore = createStore(
  {
    currentAssetMenuType: "info" as EditAssetMenuType | undefined,
  },
  ({ setter, set }) => ({
    setCurrentAssetMenuType: setter("currentAssetMenuType"),
    reset: () => {
      set({
        currentAssetMenuType: undefined,
      });
    },
  }),
);
