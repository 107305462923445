import classNames from "classnames";
import { useEffect, useRef } from "react";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { CDNImage } from "../../../components/Image/CDNImage.tsx";
import { GENERIC_STYLE_UUID } from "../../constants.ts";
import type { Style } from "../../Home/HomeIndex/types.ts";

export const StyleSecondaryNavigation = ({
  styles,
  selectedStyleUuid,
  onStyleUuidSelect,
  searchText,
  initialSelectedStyleUuid,
}: {
  styles: Style[];
  selectedStyleUuid: string | undefined;
  onStyleUuidSelect: (styleUuid: string) => void;
  searchText: string;
  initialSelectedStyleUuid?: string;
}) => {
  const isInitialFilteredStylesSetUp = useRef(!initialSelectedStyleUuid);

  // XXX: If the selected style do not match the filtered styles, we reset the selected style
  // XXX: We don't apply this effect until the initial filtered styles list is correctly initialized (e.g. with the
  //  initial selected style inside).
  useEffect(() => {
    if (styles.length && !selectedStyleUuid) {
      onStyleUuidSelect(styles[0].uuid);
    } else if (
      !styles.some((it) => it.uuid === selectedStyleUuid) &&
      isInitialFilteredStylesSetUp.current
    ) {
      onStyleUuidSelect(styles[0]?.uuid);
    } else if (
      !isInitialFilteredStylesSetUp.current &&
      styles.some((it) => it.uuid === initialSelectedStyleUuid)
    ) {
      isInitialFilteredStylesSetUp.current = true;
    }
  }, [styles, onStyleUuidSelect, selectedStyleUuid, initialSelectedStyleUuid]);

  return (
    <div className="flex-col w-[240px] pt-300 border-r text-primary">
      <div className="flex-col flex-fill px-300">
        {!searchText.isBlank() && (
          <div className="h-800 text-secondary px-150 pb-150">
            <span className="text-secondary label-sm-default">
              Search results for{" "}
            </span>
            <span className="text-primary label-sm-semibold">
              "{searchText}"
            </span>
          </div>
        )}
        <div className="flex-col flex-fill overflow-y-auto">
          {styles.map((style) => (
            <StylePickerSecondaryNavigationButton
              key={style.uuid}
              style={style}
              onClick={() => onStyleUuidSelect(style.uuid)}
              isActive={selectedStyleUuid === style.uuid}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const StylePickerSecondaryNavigationButton = ({
  onClick,
  isActive,
  style,
}: {
  onClick: () => void;
  isActive: boolean;
  style: Style;
}) => (
  <BaseButton onClick={onClick}>
    <div
      className={classNames(
        isActive
          ? "text-primary label-md-semibold bg-surface-primary-active"
          : "text-secondary label-md-default hover:bg-surface-primary-hover",
        "flex-row items-center h-800 gap-150 pl-150 pr-200 rounded-150",
      )}
    >
      <div className="w-500 h-500 rounded-100 overflow-clip border-input-border-width">
        {style.thumbnail_url ? (
          <CDNImage
            src={style.thumbnail_url}
            className="w-full h-full"
            imageClassName="h-full w-full object-cover object-center"
            srcDimension="thumbnail64"
          />
        ) : style.uuid === GENERIC_STYLE_UUID ? (
          <div className="w-full h-full flex-row-center bg-surface-emphasis-primary-rest">
            <Icon
              size="xs"
              name="PimentoStar"
              className="fill-button-primary-rest"
            />
          </div>
        ) : (
          <div className="flex-col-center h-full w-full bg-surface-secondary-rest" />
        )}
      </div>
      <div
        className={classNames(
          isActive
            ? "text-primary label-md-semibold"
            : "text-secondary label-md-semibold",
          "flex-fill truncate w-[70%]",
        )}
      >
        {style.name ? style.name : "Untitled"}
      </div>
      <div className="h-400 w-400" />
    </div>
  </BaseButton>
);
