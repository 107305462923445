import classNames from "classnames";
import { Icon, type IconName } from "../Icon/Icon.tsx";

const badgeSizeToSize = {
  sm: "h-500 px-150",
  md: "h-600 px-200",
} as const;

// FIXME: We should improve stroke color for primary button
const badgeColorToBadgeClassName: Record<string, string> = {
  brand: "text-inverse-primary stroke-inverse-primary bg-surface-inverse-rest",
  transparentDark:
    "text-inverse-primary stroke-inverse-primary bg-surface-transparent-inverse-rest",
  neutral: "text-primary stroke-primary bg-surface-secondary-rest",
  caution:
    "text-caution-primary stroke-caution bg-surface-caution-primary-rest",
  cautionSecondary:
    "text-caution-secondary stroke-caution bg-surface-caution-secondary-rest",
  error: "text-error-primary stroke-error bg-surface-error-primary-rest",
  errorSecondary:
    "text-error-secondary stroke-error bg-surface-error-secondary-rest",
  emphasis:
    "text-emphasis-primary stroke-emphasis bg-surface-emphasis-primary-rest",
  emphasisSecondary:
    "text-emphasis-secondary stroke-emphasis bg-surface-emphasis-secondary-rest",
} as const;

type BadgeColor = keyof typeof badgeColorToBadgeClassName;

type BadgeSize = keyof typeof badgeSizeToSize;

export const Badge = ({
  label,
  size,
  color,
  iconName,
  className,
}: {
  label: string;
  size: BadgeSize;
  color: BadgeColor;
  iconName?: IconName;
  className?: string;
}) => (
  <div
    className={classNames(
      badgeSizeToSize[size],
      badgeColorToBadgeClassName[color],
      "flex-row items-center rounded-full gap-050 label-md-semibold",
      className,
    )}
  >
    {iconName && <Icon name={iconName} size="sm" className="!stroke-xl" />}
    {label}
  </div>
);
