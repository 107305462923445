import { useWorkspace } from "../../../../hooks/useWorkspace.ts";
import { useAppMutation } from "../../../../http/useAppMutation.ts";
import { RenamingDialog } from "../components/RenamingDialog.tsx";
import type { Style } from "../types.ts";

export const StyleRenamingDialog = ({
  style,
  dialogOpen,
  onDialogChange,
  onCancel,
}: {
  style: Style;
  dialogOpen: boolean;
  onDialogChange: (dialogChange: boolean) => void;
  onCancel: () => void;
}) => {
  const { workspace } = useWorkspace();
  const { mutate: mutateName, isLoading: isMutateNameloading } = useAppMutation(
    {
      path: "styles/update",
      invalidate: [
        `workspaces/${workspace.uuid}/styles`,
        `styles/${style.uuid}`,
      ],
      onSuccess: () => {
        onDialogChange(false);
      },
    },
  ).mutation;

  return (
    <RenamingDialog
      initialName={style.name ?? ""}
      onDialogChange={onDialogChange}
      dialogOpen={dialogOpen}
      onClick={(name) => mutateName({ uuid: style.uuid, name })}
      isLoading={isMutateNameloading}
      onCancel={onCancel}
      title="Rename model"
      placeholder="Untitled model"
    />
  );
};
