import { useState } from "react";
import { Button } from "../../../components/Button/Button.tsx";
import { Dialog } from "../../../components/Dialog/Dialog.tsx";
import { BaseClickableIcon } from "../../../components/Icon/BaseClickableIcon.tsx";
import { useDownload } from "../../../hooks/useDownload.ts";
import { notifier } from "../../../utils/notifier.ts";
import { trackEvent } from "../../../utils/trackEvent.ts";
import type { BoardAsset } from "../types.ts";
import { ImagePurchaseMenu } from "./ImagePurchaseMenu.tsx";

export const ImageDownloadButton = ({
  asset,
  variant = "full",
}: {
  asset: BoardAsset;
  variant?: "full" | "icon" | "icon-inverse";
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [download, isDownloading] = useDownload();

  const onClick = () => {
    if (asset.content.purchased_at === null) {
      setIsDialogOpen(true);
    } else {
      download({
        url: asset.content.download_url,
        filename: `${asset.content.uuid}.png`,
        onError: () => {
          notifier.error(
            `Failed to download image from URL ${asset.content.download_url}`,
          );
        },
      });
    }
  };

  return (
    <>
      <Dialog
        isOpen={isDialogOpen || isDownloading}
        className="rounded-400 overflow-hidden"
        onOpenChange={(open) => {
          setIsDialogOpen(open);
          if (!open) {
            trackEvent("download_content:exit", {
              content_uuid: asset.content.uuid,
            });
          }
        }}
        title="Download image without watermark"
        content={
          isDialogOpen && (
            <ImagePurchaseMenu
              asset={asset}
              onDownload={() => setIsDialogOpen(false)}
            />
          )
        }
      />
      {variant === "icon" ? (
        <BaseClickableIcon
          name="Download"
          className="z-10 tile-action"
          tooltip={{ side: "top", content: "Download" }}
          onClick={onClick}
          isDisabled={isDownloading}
          isLoading={isDownloading}
        />
      ) : variant === "icon-inverse" ? (
        <BaseClickableIcon
          name="Download"
          iconClassName="stroke-inverse-rest"
          className="h-800 w-800 rounded-100 hover:bg-surface-inverse-hover active:bg-surface-inverse-active"
          tooltip={{ side: "top", content: "Download" }}
          onClick={onClick}
          isDisabled={isDownloading}
          isLoading={isDownloading}
        />
      ) : (
        <Button
          onClick={onClick}
          className="flex-row-center gap-200"
          iconName="Download"
          size="md"
        >
          Download image
        </Button>
      )}
    </>
  );
};
