import classNames from "classnames";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BaseButton } from "../../../../components/Button/BaseButton.tsx";
import { Button } from "../../../../components/Button/Button.tsx";
import { Carousel } from "../../../../components/Carousel/Carousel.tsx";
import { Icon } from "../../../../components/Icon/Icon.tsx";
import { useOnMount } from "../../../../hooks/useOnMount.ts";
import { useWorkspace } from "../../../../hooks/useWorkspace.ts";
import {
  BOARDS,
  GENERATE_TOOL_PATH,
  STYLES,
  WORKSPACES,
} from "../../../../routes.ts";
import { StylePicker } from "../../../components/StylePicker/StylePicker.tsx";
import { countNumberOfOccurencesPerStyleTags } from "../../../utils/countNumberOfOccurencesPerStyleTags.ts";
import { useCreateBoard } from "../hooks/useCreateBoard.ts";
import { useCreateStyle } from "../hooks/useCreateStyle.ts";
import { useStyles } from "../hooks/useStyles.ts";
import type { Style } from "../types.ts";
import { CUSTOM_TAG, POPULAR_TAG } from "./constants.ts";
import { NewStyleButton } from "./NewStyleButton.tsx";
import { NewStyleTile } from "./NewStyleTile.tsx";
import { StyleTile } from "./StyleTile.tsx";

export const StyleSection = () => {
  const { workspace } = useWorkspace();
  const { libraryStyles, workspaceStyles } = useStyles();

  const libraryStylesCountByTags = useMemo(
    () => countNumberOfOccurencesPerStyleTags(libraryStyles ?? []),
    [libraryStyles],
  );

  const [selectedTag, setSelectedTag] = useState<string | undefined>(
    POPULAR_TAG,
  );
  const [isStylePickerOpen, setIsStylePickerOpen] = useState(false);
  const navigate = useNavigate();

  const onStyleClick = (style: Style) => {
    navigate(`${WORKSPACES}/${workspace.uuid}/${STYLES}/${style.uuid}`);
  };
  const { createBoard } = useCreateBoard({
    onSuccess: (uuid) =>
      navigate(
        `${WORKSPACES}/${workspace.uuid}/${BOARDS}/${uuid}/${GENERATE_TOOL_PATH}`,
      ),
  });

  const { createStyle, isCreateStyleLoading } = useCreateStyle();

  return (
    <>
      <div className="flex-col">
        <div className="min-h-1600 px-800 py-400 flex-row items-center justify-between">
          <div className="flex-row gap-600 items-center">
            <div className="heading-xl">Model library</div>
            {libraryStyles !== undefined && workspaceStyles !== undefined && (
              <StyleTabs
                libraryStyles={libraryStyles}
                userStyles={workspaceStyles}
                libraryStylesCountByTags={libraryStylesCountByTags}
                selectedTag={selectedTag}
                onSelectedTagUpdate={setSelectedTag}
              />
            )}
          </div>
          <div className="flex-row gap-200">
            <Button
              size="md"
              variant="tertiary"
              onClick={() => setIsStylePickerOpen(true)}
            >
              See all
            </Button>
            <NewStyleButton />
          </div>
        </div>
        {/* FIXME: Can be split in two different sections (custom / library) */}
        {libraryStyles && (
          <div className="w-full">
            {workspaceStyles !== undefined &&
            workspaceStyles.length === 0 &&
            selectedTag === CUSTOM_TAG ? (
              <div className="px-1000">
                <BaseButton
                  loading={isCreateStyleLoading}
                  onClick={() => createStyle()}
                  className="w-full h-[220px] bg-surface-primary-rest border-0255 rounded-100 group hover:border-emphasis-rest hover:bg-surface-primary-hover flex-col-center gap-400"
                >
                  <Icon name="Palette" className="stroke-emphasis-rest" />
                  <span className="label-lg-semibold text-emphasis-secondary">
                    Generate endless content in your style
                  </span>
                  <div className="h-1000 pl-200 pr-600 bg-surface-primary-rest label-lg-default rounded-100 border flex-row items-center gap-400 group-hover:opacity-70 group-active:bg-surface-inverse-rest group-active:text-white group-active:stroke-inverse-rest">
                    <Icon name="Plus" />
                    Create your first model
                  </div>
                </BaseButton>
              </div>
            ) : (
              <div className="pl-800 w-full flex-row">
                <div className="flex-row gap-200">
                  <NewStyleTile />
                  <div className="border-l h-[220px]" />
                </div>
                <div className="flex-fill">
                  <Carousel
                    items={[
                      ...((selectedTag === CUSTOM_TAG ||
                        selectedTag === undefined) &&
                      workspaceStyles
                        ? workspaceStyles.map((workspaceStyle) => (
                            <StyleTile
                              key={workspaceStyle.uuid}
                              style={workspaceStyle}
                              onClick={() => onStyleClick(workspaceStyle)}
                            />
                          ))
                        : []),
                      ...libraryStyles
                        .filter((style) =>
                          selectedTag ? style.tags.includes(selectedTag) : true,
                        )
                        .let((filteredStyles) =>
                          filteredStyles.map((libraryStyle) => (
                            <StyleTile
                              key={libraryStyle.uuid}
                              style={libraryStyle}
                              onClick={() => onStyleClick(libraryStyle)}
                            />
                          )),
                        ),
                    ]}
                    leftButtonClassName="top-[86px] left-1000"
                    rightButtonClassName="top-[86px] right-1000"
                    className="pl-200 pr-800 gap-200"
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <StylePicker
        dialogOpen={isStylePickerOpen}
        onDialogChange={(open) => setIsStylePickerOpen(open)}
        onStyleSelect={(styleUuid) => {
          createBoard({ styleUuid });
        }}
      />
    </>
  );
};

const StyleTabs = ({
  libraryStyles,
  userStyles,
  libraryStylesCountByTags,
  selectedTag,
  onSelectedTagUpdate,
}: {
  libraryStyles: Style[];
  userStyles: Style[];
  libraryStylesCountByTags: { [_: string]: number | undefined };
  selectedTag: string | undefined;
  onSelectedTagUpdate: (tag: string | undefined) => void;
}) => {
  const libraryStylesCount = libraryStyles.length;

  useOnMount(() => {
    if (userStyles.length > 0) onSelectedTagUpdate(CUSTOM_TAG);
  });

  return (
    <div className="flex-row gap-400 label-md-semibold text-primary">
      {Object.keys(libraryStylesCountByTags).includes(POPULAR_TAG) && (
        <BaseButton onClick={() => onSelectedTagUpdate(POPULAR_TAG)}>
          <span
            className={classNames(
              POPULAR_TAG === selectedTag
                ? "text-emphasis-secondary underline underline-offset-4 decoration-[1.5px]"
                : "text-disabled",
            )}
          >
            {POPULAR_TAG} ({libraryStylesCountByTags[POPULAR_TAG]})
          </span>
        </BaseButton>
      )}
      <BaseButton onClick={() => onSelectedTagUpdate(CUSTOM_TAG)}>
        <span
          className={classNames(
            CUSTOM_TAG === selectedTag
              ? "text-emphasis-secondary underline underline-offset-4 decoration-[1.5px]"
              : "text-disabled",
          )}
        >
          My Models ({userStyles.length})
        </span>
      </BaseButton>
      <BaseButton onClick={() => onSelectedTagUpdate(undefined)}>
        <span
          className={classNames(
            selectedTag === undefined
              ? "text-emphasis-secondary underline underline-offset-4 decoration-[1.5px]"
              : "text-disabled",
          )}
        >
          All ({libraryStylesCount + userStyles.length})
        </span>
      </BaseButton>
      {Object.keys(libraryStylesCountByTags)
        .filter((tagName) => tagName !== POPULAR_TAG)
        .sort()
        .map((tagName) => (
          <BaseButton
            key={tagName}
            onClick={() => onSelectedTagUpdate(tagName)}
          >
            <span
              className={classNames(
                tagName === selectedTag
                  ? "text-emphasis-secondary underline underline-offset-4 decoration-[1.5px]"
                  : "text-disabled",
              )}
            >
              {tagName} ({libraryStylesCountByTags[tagName]})
            </span>
          </BaseButton>
        ))}
    </div>
  );
};
