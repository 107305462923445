import { BaseButton } from "../../../../components/Button/BaseButton.tsx";
import { Icon } from "../../../../components/Icon/Icon.tsx";
import { useCreateStyle } from "../hooks/useCreateStyle.ts";

export const NewStyleTile = () => {
  const { createStyle, isCreateStyleLoading } = useCreateStyle();

  return (
    <BaseButton
      className="rounded-100 group flex-col-center items-center gap-200 bg-input-surface-rest hover:bg-surface-primary-hover"
      style={{ height: 220, width: 180 }}
      onClick={() => createStyle()}
      loading={isCreateStyleLoading}
    >
      <Icon name="Plus" size="lg" />
      <div className="text-primary label-lg-semibold">New model</div>
    </BaseButton>
  );
};
