import { useState } from "react";
import { Button } from "../../../../components/Button/Button.tsx";
import { Dialog } from "../../../../components/Dialog/Dialog.tsx";
import { TextField } from "../../../../components/TextField/TextField.tsx";

export const RenamingDialog = ({
  initialName,
  dialogOpen,
  onDialogChange,
  onCancel,
  isLoading,
  onClick,
  title,
  placeholder,
}: {
  initialName: string;
  dialogOpen: boolean;
  onDialogChange: (dialogChange: boolean) => void;
  onCancel: () => void;
  isLoading?: boolean;
  onClick: (name: string) => void;
  title: string;
  placeholder: string;
}) => {
  const [name, setName] = useState(initialName);

  return (
    <Dialog
      title={title}
      isOpen={dialogOpen}
      onOpenChange={onDialogChange}
      content={
        <div className="w-[300px] flex-col">
          <TextField
            className="px-400 py-100"
            value={name}
            onChange={(newName) => setName(newName)}
            size="md"
            placeholder={placeholder}
          />
          <div className="px-400 py-300 flex-row justify-end gap-200">
            <Button
              variant="tertiary"
              size="md"
              onClick={() => onCancel()}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              size="md"
              loading={isLoading}
              onClick={() => onClick(name)}
            >
              Rename
            </Button>
          </div>
        </div>
      }
    />
  );
};
