import type { IconName } from "../components/Icon/Icon.tsx";
import type { MLModelArchitecture } from "./Board/types.ts";
import type { STYLE_IMAGES_KIND, STYLE_TYPES } from "./constants.ts";

export type StyleStatus = "created" | "training" | "ready" | "error";

export type StyleImagesKind = (typeof STYLE_IMAGES_KIND)[number];

export type StyleType = (typeof STYLE_TYPES)[number];

export type StyleImage = {
  url: string;
  uuid: string;
};

export type ObjectDetails = {
  labels: string[] | null;
  materials: string[] | null;
};

export type PhotographyColorDetails = {
  palette: string[] | null;
};

export type IllustrationColorDetails = {
  palette: string[] | null;
  texture: string[] | null;
};

export type PhotographyStyleDetails = {
  camera_type: string[] | null;
  composition: string[] | null;
  focus: string[] | null;
  exposure: string[] | null;
  image_quality: string[] | null;
};

export type IllustrationStyleDetails = {
  composition: string[] | null;
  level_of_detail: string[] | null;
  shape: string[] | null;
  stroke: string[] | null;
};

export type IconStyleDetails = {
  composition: string[] | null;
  level_of_detail: string[] | null;
  shape: string[] | null;
  stroke: string[] | null;
};

export type Graphic3DStyleDetails = {
  composition: string[] | null;
  level_of_detail: string[] | null;
  shape: string[] | null;
};

type BaseStyle = {
  uuid: string;
  name: string | null;
  status: StyleStatus;
  images_kind: StyleImagesKind | null;
  type: StyleType | null;
  training_images: StyleImage[];
  is_curated: boolean;
  thumbnail_url: string | null;
  ml_model_architectures: MLModelArchitecture[];
  is_mixable: boolean;
  additional_elements: string | null;
  exclude_elements: string | null;
};

export type ObjectStyle = BaseStyle & {
  type: "object";
  object_details: ObjectDetails | null;
  subject_description: string | null;
};

export type CharacterStyle = BaseStyle & {
  type: "character";
  subject_description: string | null;
};

type BaseModelStyle = BaseStyle & {
  type: "style";
};

export type PhotographyModelStyle = BaseModelStyle & {
  images_kind: "photography";
  color_details: PhotographyColorDetails | null;
  style_details: PhotographyStyleDetails | null;
};

export type IllustrationModelStyle = BaseModelStyle & {
  images_kind: "illustration_2d";
  color_details: IllustrationColorDetails | null;
  style_details: IllustrationStyleDetails | null;
};

export type Graphic3DModelStyle = BaseModelStyle & {
  images_kind: "graphic_3d";
  color_details: IllustrationColorDetails | null;
  style_details: Graphic3DStyleDetails | null;
};
export type IconModelStyle = BaseModelStyle & {
  images_kind: "icon";
  color_details: IllustrationColorDetails | null;
  style_details: IconStyleDetails | null;
};
export type OtherModelStyle = BaseModelStyle & {
  images_kind: "other";
};

export type UndefinedModelStyle = BaseModelStyle & {
  images_kind: null;
};

export type UndefinedStyle = BaseStyle & {
  type: null;
};

export type ModelStyle =
  | PhotographyModelStyle
  | IllustrationModelStyle
  | IconModelStyle
  | Graphic3DModelStyle
  | OtherModelStyle
  | UndefinedModelStyle;

export type Style = ObjectStyle | CharacterStyle | ModelStyle | UndefinedStyle;

export const IMAGE_KIND_TO_ICON_NAMES: Record<StyleImagesKind, IconName> = {
  photography: "Camera",
  illustration_2d: "Brush",
  graphic_3d: "Brush",
  icon: "Brush",
  other: "Brush",
};

export const IMAGE_KIND_TO_LABELS: Record<StyleImagesKind, string> = {
  photography: "Photography",
  illustration_2d: "Illustration 2D",
  graphic_3d: "Graphic 3D",
  icon: "Icon",
  other: "Other",
};
