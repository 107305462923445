import * as SwitchPrimitives from "@radix-ui/react-switch";
import classNames from "classnames";
import React from "react";
import { wrapFormComponentHOC } from "../Form/wrapFormComponentHOC.tsx";

type SwitchProps = Omit<
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>,
  "checked" | "onCheckedChange" | "value" | "onChange"
> & {
  value: boolean;
  onChange: (value: boolean) => void;
  error?: string | undefined;
  disabled?: boolean;
};

export const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  SwitchProps
>(({ className, value, onChange, disabled, error: _, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={classNames(
      "peer inline-flex h-[20px] w-[32px] shrink-0 cursor-pointer items-center rounded-400 transition-colors focus-visible:outline-none disabled:cursor-not-allowed data-[disabled]:bg-button-surface-primary-disabled data-[state=checked]:bg-inverse data-[state=unchecked]:bg-primary",
      className,
    )}
    disabled={disabled}
    checked={value}
    onCheckedChange={onChange}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={classNames(
        "pointer-events-none h-[16px] w-[16px] rounded-full shadow-100 transition-transform bg-surface-primary-rest data-[state=checked]:translate-x-[14px] data-[state=unchecked]:translate-x-[2px]",
      )}
    />
  </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export const FormSwitch = wrapFormComponentHOC<boolean, SwitchProps>(Switch);
