export const Gmail = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    stroke="none"
  >
    <path
      d="M1.09091 12H3.63637V5.81978L0 3.09322V10.9094C0 11.5129 0.489092 12 1.09091 12Z"
      fill="#4285F4"
    />
    <path
      d="M12.3638 12H14.9092C15.5129 12 16.0001 11.511 16.0001 10.9094V3.09322L12.3638 5.81978"
      fill="#34A853"
    />
    <path
      d="M12.3638 1.09372V5.81977L16.0001 3.09321V1.63904C16.0001 0.290293 14.4602 -0.4786 13.382 0.330282"
      fill="#FBBC04"
    />
    <path
      d="M3.63623 5.81977V1.09372L7.99989 4.3656L12.3635 1.09372V5.81977L7.99989 9.09166"
      fill="#EA4335"
    />
    <path
      d="M0 1.63904V3.09321L3.63637 5.81977V1.09372L2.61819 0.330282C1.53819 -0.4786 0 0.290293 0 1.63904Z"
      fill="#C5221F"
    />
  </svg>
);
