import { memo } from "react";
import { Button } from "../../../../components/Button/Button.tsx";
import { useDownload } from "../../../../hooks/useDownload.ts";
import { notifier } from "../../../../utils/notifier.ts";
import { ImageTile } from "../../../Board/components/ImageTile.tsx";
import type { ImageContent } from "../../../Board/types.ts";

export const MasonryContentTile = memo(
  ({ content }: { content: ImageContent }) => {
    const [download, isDownloading] = useDownload();
    return (
      <div className="w-full group relative overflow-clip block">
        {content.status === "succeeded" && (
          <>
            <ImageTile image={content} fit="width" srcDimension="xlarge1024" />
            <div className="absolute left-0 bottom-0 w-full h-full invisible group-hover:visible bg-gradient-to-t from-[#1D1E21]/80 to-65%" />
            <div className="absolute p-400 w-full bottom-0 left-0 invisible group-hover:visible">
              <Button
                className="w-full"
                variant="inverse-primary"
                iconName="Download"
                onClick={() => {
                  // FIXME: Duplication of the download function in ImageDownloadButton. To be refactored.
                  download({
                    url: content.download_url,
                    filename: `${content.uuid}.png`,
                    onError: () => {
                      notifier.error(
                        `Failed to download image from URL ${content.download_url}`,
                      );
                    },
                  });
                }}
                loading={isDownloading}
              >
                Download
              </Button>
            </div>
          </>
        )}
      </div>
    );
  },
);
MasonryContentTile.displayName = "MasonryContentTile";
