export const Outlook = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M15.6286 7.97684L15.6204 7.96674L15.612 7.95643L15.5314 7.95633L15.3729 7.95615C15.3711 7.96763 15.3701 7.97916 15.3699 7.99065C15.3713 8.0017 15.3734 8.01276 15.3763 8.02376L15.5488 8.00504L15.6144 7.99792M15.6286 7.97684L15.6188 7.99133L15.6144 7.99792M15.6286 7.97684L15.6357 7.98561M15.6286 7.97684L15.6423 7.95647L15.6541 7.95648L15.6357 7.98561M15.6144 7.99792L15.4886 8.18482C15.4934 8.18826 15.4984 8.19159 15.5035 8.19481L15.6289 7.99634M15.6144 7.99792L15.6289 7.99634M15.6289 7.99634L15.6308 7.99613L15.6432 7.9948L15.6357 7.98561M15.6289 7.99634L15.6294 7.99557L15.6357 7.98561M10.7962 5.05357L10.8042 5.0479C10.8082 5.04726 10.812 5.04697 10.8157 5.04697C10.8196 5.04697 10.8236 5.04728 10.8278 5.04799L15.1818 7.9786L10.8547 10.8911L10.8486 10.8952L10.8426 10.8994L10.8346 10.9051C10.8306 10.9057 10.8268 10.906 10.8231 10.906C10.8194 10.906 10.8156 10.9057 10.8116 10.9051L10.8036 10.8994L10.7877 10.8882L10.7712 10.8779L6.07424 7.97421L10.7638 5.07506L10.7803 5.06482L10.7962 5.05357ZM15.6787 7.99094L15.6555 7.95648L15.9927 7.95688L15.6787 7.99094ZM15.6616 7.94475L15.6548 7.95545L15.6513 7.95024L15.6489 7.94669L15.8374 7.66655L15.6616 7.94475Z"
      fill="#123B6D"
      stroke="#494949"
      stroke-width="1.25"
    />
    <path
      d="M8.91263 6.19122H5.57959V9.43203H8.91263V6.19122Z"
      fill="#0364B8"
    />
    <path
      d="M15.1577 3.09163V1.6164C15.1657 1.43242 15.105 1.25251 14.9887 1.11583C14.8725 0.979155 14.7101 0.896775 14.5369 0.886627H6.20797C6.12157 0.890658 6.03676 0.912742 5.95841 0.951617C5.88005 0.990491 5.80968 1.04539 5.75134 1.11318C5.69299 1.18096 5.6478 1.2603 5.61837 1.34665C5.58893 1.433 5.57582 1.52466 5.5798 1.6164V3.09163H15.1577Z"
      fill="#0358A7"
    />
    <path
      d="M8.91263 3.09171H5.57959V6.18342H8.91263V3.09171Z"
      fill="#0078D4"
    />
    <path
      d="M12.2461 3.09171H8.91309V6.18342H12.2461V3.09171Z"
      fill="#28A8EA"
    />
    <path
      d="M15.1579 3.09171H12.2461V6.18342H15.1579V3.09171Z"
      fill="#50D9FF"
    />
    <path
      d="M15.1579 6.19122H12.2461V9.43203H15.1579V6.19122Z"
      fill="#28A8EA"
    />
    <path
      d="M12.2461 6.19122H8.91309V9.43203H12.2461V6.19122Z"
      fill="#0078D4"
    />
    <path d="M12.2461 9.4321H8.91309V12.6729H12.2461V9.4321Z" fill="#0364B8" />
    <path d="M8.91263 9.4321H5.57959V12.3826H8.91263V9.4321Z" fill="#14447D" />
    <path d="M15.1579 9.4321H12.2461V12.6729H15.1579V9.4321Z" fill="#0078D4" />
    <path
      d="M15.8301 8.24716L10.982 11.386L10.9081 11.433C10.8079 11.4845 10.698 11.5112 10.5866 11.5112C10.4753 11.5112 10.3654 11.4845 10.2652 11.433V11.433L10.1913 11.386L4.92934 8.24716C4.87899 8.22026 4.83693 8.17863 4.80812 8.12719C4.77932 8.07575 4.76496 8.01663 4.76675 7.95682V7.95682V14.2736C4.76675 14.4818 4.84461 14.6813 4.98321 14.8285C5.1218 14.9757 5.30978 15.0583 5.50578 15.0583H15.261C15.457 15.0583 15.645 14.9757 15.7836 14.8285C15.9222 14.6813 16.0001 14.4818 16.0001 14.2736V7.95682C15.9994 8.01709 15.9833 8.07603 15.9533 8.12722C15.9233 8.17841 15.8807 8.21989 15.8301 8.24716V8.24716Z"
      fill="url(#paint0_linear_2346_5502)"
    />
    <path
      d="M5.46882 15.0191H15.2758C15.4273 15.0164 15.5743 14.9644 15.697 14.87L10.1986 11.3075L10.1247 11.2604L4.95149 8.05098L4.75195 7.95682V14.2344C4.75187 14.4385 4.82668 14.6346 4.96051 14.7811C5.09435 14.9276 5.27668 15.013 5.46882 15.0191V15.0191Z"
      fill="url(#paint1_linear_2346_5502)"
    />
    <g style={{ mixBlendMode: "multiply" }} opacity="0.48">
      <rect
        y="3.01329"
        width="10.4647"
        height="11.1113"
        fill="url(#pattern0_2346_5502)"
      />
      <g style={{ mixBlendMode: "multiply" }} opacity="0.48">
        <path
          d="M8.28456 3.97845H2.04712C1.70019 3.97845 1.41895 4.27708 1.41895 4.64545V11.2683C1.41895 11.6367 1.70019 11.9353 2.04712 11.9353H8.28456C8.6315 11.9353 8.91274 11.6367 8.91274 11.2683V4.64545C8.91274 4.27708 8.6315 3.97845 8.28456 3.97845Z"
          fill="white"
        />
      </g>
    </g>
    <g style={{ mixBlendMode: "multiply" }} opacity="0.24">
      <rect
        x="1.22656"
        y="3.75085"
        width="7.98156"
        height="8.47474"
        fill="url(#pattern1_2346_5502)"
      />
      <g style={{ mixBlendMode: "multiply" }} opacity="0.24">
        <path
          d="M8.28456 3.97847H2.04712C1.70019 3.97847 1.41895 4.27709 1.41895 4.64546V11.2683C1.41895 11.6367 1.70019 11.9353 2.04712 11.9353H8.28456C8.6315 11.9353 8.91274 11.6367 8.91274 11.2683V4.64546C8.91274 4.27709 8.6315 3.97847 8.28456 3.97847Z"
          fill="white"
        />
      </g>
    </g>
    <path
      style={{ mixBlendMode: "soft-light" }}
      opacity="0.75"
      d="M15.9927 7.95676C15.9928 7.89823 15.9785 7.84069 15.9513 7.78978C15.9241 7.73888 15.8849 7.69637 15.8375 7.66642V7.66642L15.165 7.25838V1.6164C15.1731 1.43242 15.1124 1.25251 14.9961 1.11583C14.8798 0.979155 14.7174 0.896775 14.5442 0.886627H6.20793C6.12153 0.890658 6.03672 0.912742 5.95836 0.951617C5.88001 0.990491 5.80964 1.04539 5.75129 1.11318C5.69295 1.18096 5.64776 1.2603 5.61833 1.34665C5.58889 1.433 5.57578 1.52466 5.57975 1.6164V8.67868L4.92201 8.28633C4.87166 8.25943 4.82961 8.21781 4.8008 8.16637C4.77199 8.11493 4.75764 8.05581 4.75943 7.99599V7.99599V14.3128C4.75943 14.5209 4.83729 14.7205 4.97588 14.8677C5.11448 15.0148 5.30246 15.0975 5.49846 15.0975H15.2611C15.4571 15.0975 15.6451 15.0148 15.7837 14.8677C15.9223 14.7205 16.0001 14.5209 16.0001 14.3128V7.95676H15.9927Z"
      fill="url(#paint2_linear_2346_5502)"
    />
    <path
      d="M8.28456 3.97847H2.04712C1.70019 3.97847 1.41895 4.27709 1.41895 4.64546V11.2683C1.41895 11.6367 1.70019 11.9353 2.04712 11.9353H8.28456C8.6315 11.9353 8.91274 11.6367 8.91274 11.2683V4.64546C8.91274 4.27709 8.6315 3.97847 8.28456 3.97847Z"
      fill="#0F78D4"
    />
    <g style={{ mixBlendMode: "soft-light" }} opacity="0.5">
      <path
        style={{ mixBlendMode: "soft-light" }}
        opacity="0.5"
        d="M8.28456 3.97847H2.04712C1.70019 3.97847 1.41895 4.27709 1.41895 4.64546V11.2683C1.41895 11.6367 1.70019 11.9353 2.04712 11.9353H8.28456C8.6315 11.9353 8.91274 11.6367 8.91274 11.2683V4.64546C8.91274 4.27709 8.6315 3.97847 8.28456 3.97847Z"
        fill="url(#paint3_linear_2346_5502)"
      />
    </g>
    <path
      d="M3.47367 6.86613C3.61442 6.53837 3.8466 6.26445 4.1388 6.08143C4.46616 5.88108 4.84023 5.78314 5.21779 5.79894C5.56189 5.79391 5.90109 5.88598 6.2007 6.06573C6.48437 6.25282 6.70841 6.52625 6.84366 6.85043C7.00254 7.19158 7.08115 7.56854 7.07276 7.94901C7.08168 8.34465 7.00322 8.73701 6.84366 9.09467C6.69194 9.41709 6.45919 9.68867 6.17114 9.87937C5.86317 10.0662 5.51331 10.1611 5.15867 10.154C4.80634 10.1613 4.45883 10.0663 4.15358 9.87937C3.87208 9.70228 3.64393 9.4437 3.49584 9.1339C3.33813 8.79512 3.25955 8.42101 3.26674 8.04317C3.2459 7.63889 3.31687 7.2352 3.47367 6.86613V6.86613ZM4.2127 8.70232C4.28634 8.907 4.41479 9.08431 4.58222 9.21237C4.75559 9.33993 4.96258 9.40586 5.17345 9.4007C5.39406 9.4104 5.61174 9.34436 5.79423 9.21237C5.96101 9.08028 6.08914 8.90071 6.16375 8.69447C6.23999 8.46252 6.2775 8.21821 6.27461 7.97255C6.27569 7.72766 6.24078 7.48409 6.17114 7.25063C6.10416 7.03998 5.98091 6.85458 5.8164 6.71703C5.63866 6.57332 5.41874 6.50105 5.19562 6.51301C4.98244 6.5078 4.77309 6.57366 4.597 6.70134C4.42476 6.83125 4.29136 7.01104 4.2127 7.21924C4.03529 7.69565 4.03529 8.22591 4.2127 8.70232V8.70232Z"
      fill="white"
    />
    <defs>
      <pattern
        id="pattern0_2346_5502"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use xlinkHref="#image0_2346_5502" transform="scale(0.0166667)" />
      </pattern>
      <pattern
        id="pattern1_2346_5502"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use xlinkHref="#image1_2346_5502" transform="scale(0.0217391)" />
      </pattern>
      <linearGradient
        id="paint0_linear_2346_5502"
        x1="14.219"
        y1="6.37957"
        x2="5.93951"
        y2="15.6756"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#35B8F1" />
        <stop offset="0.9" stop-color="#0F78D1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2346_5502"
        x1="10.2208"
        y1="7.95682"
        x2="10.2208"
        y2="15.0269"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#35B8F1" />
        <stop offset="1" stop-color="#28A8EA" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2346_5502"
        x1="6.10447"
        y1="0.855239"
        x2="14.9784"
        y2="15.3269"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0.5" />
        <stop offset="1" stop-opacity="0.7" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2346_5502"
        x1="2.71964"
        y1="3.46057"
        x2="8.06495"
        y2="12.1754"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0.5" />
        <stop offset="1" stop-opacity="0.7" />
      </linearGradient>
      <image
        id="image0_2346_5502"
        width="60"
        height="60"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAC4jAAAuIwF4pT92AAADoUlEQVRoQ+2b61LbMBBGj3IhoUAphbbT93+6Ti8DoZCEXNwfq43WshyHUizi+pvZ8SWO2ONd2WH82RVFwf+kUdMBKTnnXNMxr63iLyvl9n3PgFnA7LBGhV0echJqgT2sAwZ+add3hyW++tqykBpbu70PPAnsnBsgcANgGIU9AbmANTaJ2ALbOujKHDawI2AMnJgY+dBjoF1ohdgicGtgBTyZWAE455LQJWDfxgo7AabAO+AMOPX7TqhWui3ZFl4jgHPg0YTz+5PQO+Bozo4RwAvg0sd7BH7qPx/S3ny2SWt1Vwjsb2AG3CI56fE2dopbWqt7gsBdAp+AG+Cj3z6lXOU2pQBrYAk8AHfATwJLaT4755ytsgXWCg+R1j0DPiDAX4HPwBVwTpjPOVpa23kJ3AO/kHwx+5dIW6/jAVIVHiJAp0gbXwNfEOhrpM0nhJbOAbwBFkgrT/1nWvF7v9QO1FsWUAXWCo+Rgc4Q6CukrW/ID6wtvSBUdoG09jlSqNQ1BvDA5oKlFy2tsl6lz31c8DaAN0h+IJW9INxJdLol80v9lrbQ9l48iSI3MMg8nRJyGpOAtReuVEvr0oLbGJn1NmFVOh9tLiOzrj+KkrnVVTgVdiAbOVSXlwVN5nfofTT+Yi5QVV1RiNYragLODfbP1QR8bGosUNeAG9UDd109cNfVA3ddPXDX1QN3XT1w19UDd109cNfVA3ddPXDX1QMfuWrdO6om4MYBjk1NwKoYPPeJiD0c1stR8XVY1VkP4wHsvvgPtK04hy3hKb992p/MMQauGyxlAIP2nyBqXprD2ix13Z6AilIVtoOq8UvNX0vCk/ccz4dtbgsTamRZEcB3Fa64eIqiKLyPVKuqsEs/4IMPNZA8kRdYPR4zH2pkmRPcOyVola2wbeMVAvvoB7tF/BMgf0h9WjmAYxfPD8S6dIeY1OZI/gpcUmoOq8tNYa0Pao6YR9QlA+1CK3Ds0/qGmNNmSN5PhPm8t8J69rS6d5TdMrcE66G1F7Ql29KxE+87kp8FPqjCatp8RAbAb88QC9OE4KhVvTa4TXyf1zKucMU7vQP2F664pUFOgFZbYXNYlqB8nalz0y6Q/JO3ppJB3BjU1I1nPVq6bWFzAEOzX3pDjUm84oiPbMSxL2tAmLttw6oKE6kfRHsd8XWvAMR2oEG0jVm2rcIsbYvvtp/9zsPuw+pbLbkg62ThX/ZWS53ewjtLcBhcSs8GPnYd+v9wZ/QHTbqvfA0XAmsAAAAASUVORK5CYII="
      />
      <image
        id="image1_2346_5502"
        width="46"
        height="46"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAYAAABXuSs3AAAACXBIWXMAAC4jAAAuIwF4pT92AAAAwUlEQVRoQ+3ZsQkCMRyF8S8iZAm765zAzr0cwJ3sLG8BN8gQSfW3CIdeZ/VM4P0gTSDwcXDVSxFBSikDJyAzpgaUiGjbRaLHXoAbPX5EBbgD63f8AjyACsSgpwJP4ArkiADgDLx+ePzvU+kfeIkIDsxj9x/OFL7jcDWHqzlczeFqDldzuJrD1Ryu5nA1h6s5XM3hag5Xc7iaw9UcruZwNYcLNPre2aCH7y4G1YCVPtIWgCOf1RYmWpbTtFv+Ni/P5g1xTodLg4W2uAAAAABJRU5ErkJggg=="
      />
    </defs>
  </svg>
);
