import { Image } from "../../../../../../components/Image/Image.tsx";
import { useAppQuery } from "../../../../../../http/useAppQuery.ts";
import { useSelectedAsset } from "../../../../hooks/useSelectedAsset.ts";
import type { ImageContent } from "../../../../types.ts";
import { overlayDisplayedContentUuidStore } from "../../stores/overlayDisplayedContentUuidStore.ts";
import { BaseImageContainer } from "./BaseImageContainer.tsx";

export const ImageContainer = () => {
  const { selectedAsset } = useSelectedAsset();

  const { overlayDisplayedContentUuid } =
    overlayDisplayedContentUuidStore.useState();

  const { data: overlayImage } = useAppQuery<ImageContent>({
    queryKey: overlayDisplayedContentUuid
      ? `contents/${overlayDisplayedContentUuid}`
      : null,
    enabled: overlayDisplayedContentUuid !== undefined,
  });

  return overlayDisplayedContentUuid ? (
    <BaseImageContainer image={overlayImage}>
      <Image
        className="max-h-full max-w-full min-w-[256px] min-h-[256px]"
        imageClassName="h-full w-full object-contain"
        src={overlayImage?.url}
      />
    </BaseImageContainer>
  ) : (
    <BaseImageContainer image={selectedAsset?.content}>
      {selectedAsset?.content === undefined ? null : (
        <Image
          className="max-h-full max-w-full min-w-[256px] min-h-[256px]"
          imageClassName="h-full w-full object-contain"
          src={selectedAsset.content.url}
        />
      )}
    </BaseImageContainer>
  );
};
