import classNames from "classnames";
import { Badge } from "../../../../components/Badge/Badge.tsx";
import { BaseButton } from "../../../../components/Button/BaseButton.tsx";
import { Icon, type IconName } from "../../../../components/Icon/Icon.tsx";

export const ActionTileButton = ({
  title,
  description,
  timeEstimation,
  iconName,
  onClick,
  isLoading = false,
  hasTagNew = false,
  isBeta = false,
}: {
  title: string;
  description?: string;
  timeEstimation: string;
  iconName: IconName;
  onClick: () => void;
  isLoading?: boolean;
  hasTagNew?: boolean;
  isBeta?: boolean;
}) => (
  <BaseButton
    onClick={onClick}
    className={classNames(
      "group w-[270px] min-h-[114px] p-300 border rounded-200 flex-row gap-200 hover:bg-surface-primary-hover hover:border-primary-hover",
      isLoading && "items-center",
    )}
    loading={isLoading}
  >
    <Icon name={iconName} />
    <div className="flex-fill flex-col justify-between gap-300">
      <div className="flex-col">
        <span className="label-lg-semibold text-primary">{title}</span>
        {description && (
          <span className="label-md-default text-secondary">{description}</span>
        )}
      </div>
      <div className="flex-row items-center gap-100">
        <Badge
          size="sm"
          iconName="Timer"
          color="neutral"
          label={timeEstimation}
        />
        {hasTagNew && (
          <Badge size="sm" iconName="BadgeCheck" color="caution" label="New" />
        )}
        {isBeta && <Badge size="sm" label="Beta" color="emphasisSecondary" />}
      </div>
    </div>
    <Icon
      name="ChevronRight"
      className="stroke-secondary-rest group-hover:stroke-secondary-hover"
      size="sm"
    />
  </BaseButton>
);
