import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import type { IconName } from "../../../../components/Icon/Icon.tsx";
import { useWorkspace } from "../../../../hooks/useWorkspace.ts";
import {
  BOARDS,
  BRIEF_TOOL_PATH,
  GENERATE_TOOL_PATH,
  WORKSPACES,
} from "../../../../routes.ts";
import { STYLE_TYPE_ICON_NAMES } from "../../../Board/constants.ts";
import { GENERIC_STYLE_UUID } from "../../../constants.ts";
import type { StyleType } from "../../../types.ts";
import { useCreateBoard } from "../hooks/useCreateBoard.ts";
import { useCreateStyle } from "../hooks/useCreateStyle.ts";
import { ActionTileButton } from "./ActionTileButton.tsx";

export const QuickActionsSection = () => {
  const { workspace } = useWorkspace();
  // XXX: We use ref and not local state here to avoid having rendering order issue. The actionSelected state is
  // not necessary updated otherwise when used in the onSuccess that can lead to unexpected behavior.
  const actionSelected = useRef<HomepageAction | null>(null);
  const navigate = useNavigate();

  const { createBoard } = useCreateBoard({
    onSuccess: (uuid) => {
      if (actionSelected.current === null) return;
      navigate(
        `${WORKSPACES}/${workspace.uuid}/${BOARDS}/${uuid}/${actionSelected.current.route}`,
      );
    },
  });

  return (
    <div className="flex-col">
      <div className="min-h-1600 px-800 py-400 heading-xl flex-row items-center">
        Quick actions
      </div>
      <div className="px-800 flex-row flex-wrap gap-400">
        {CreateActions.map((action, index) => (
          <ActionTileButton
            key={index}
            title={action.title}
            description={action.description}
            timeEstimation={action.timeEstimation}
            iconName={action.iconName}
            onClick={() => {
              actionSelected.current = action;
              createBoard({ styleUuid: GENERIC_STYLE_UUID });
            }}
            hasTagNew={action.hasTagNew}
          />
        ))}
        <NewStyleButton
          title="Build your style model"
          description="Create style consistent AI"
          type="style"
        />
        <NewStyleButton
          title="Build your character model"
          description="Create character consistent AI"
          type="character"
        />
        <NewStyleButton
          title="Build your object model"
          description="Create object consistent AI"
          type="object"
          isBeta
        />
      </div>
    </div>
  );
};

type HomepageAction = {
  title: string;
  description?: string;
  timeEstimation: string;
  iconName: IconName;
  hasTagNew?: boolean;
  route: typeof GENERATE_TOOL_PATH | typeof BRIEF_TOOL_PATH;
};

const CreateActions: HomepageAction[] = [
  {
    title: "Generate images from prompt",
    description: "Bring ideas to life",
    iconName: "Sparkles",
    route: GENERATE_TOOL_PATH,
    timeEstimation: "30 sec",
  },
  {
    title: "Generate images from brief",
    description: "Start with a campaign brief",
    iconName: "Grid3x3",
    route: BRIEF_TOOL_PATH,
    timeEstimation: "1 min",
    hasTagNew: true,
  },
];

const NewStyleButton = ({
  title,
  description,
  type,
  isBeta,
}: {
  title: string;
  description: string;
  type: StyleType;
  isBeta?: boolean;
}) => {
  const { createStyle, isCreateStyleLoading } = useCreateStyle();
  return (
    <ActionTileButton
      title={title}
      description={description}
      iconName={STYLE_TYPE_ICON_NAMES[type]}
      onClick={() => createStyle({ type })}
      isLoading={isCreateStyleLoading}
      timeEstimation="15 min"
      isBeta={isBeta}
    />
  );
};
