import React, { useEffect, useState } from "react";
import { wrapFormComponentHOC } from "../Form/wrapFormComponentHOC.tsx";
import { TextField } from "../TextField/TextField.tsx";

type InputNumberProps = {
  value: number;
  onChange: (newValue: number) => void;
  min?: number;
  max?: number;
  className?: string;
  style?: React.CSSProperties;
  rightSuffix?: string;
};

export const InputNumber = ({
  value,
  onChange,
  min,
  max,
  className,
  style,
  rightSuffix,
}: InputNumberProps) => {
  const [inputValue, setInputValue] = useState<string>(`${value}`); // can be NaN

  useEffect(() => {
    setInputValue(`${value}`);
  }, [value]);

  return (
    <TextField
      size="sm"
      type="number"
      className={className}
      inputClassName="hide-controls"
      value={inputValue}
      style={style}
      onChange={(text) => {
        setInputValue(text);
        const val = Number(text);
        if (!isNaN(val)) {
          const boundedMax = max ? Math.min(max, val) : val;
          const boundedBoth = min ? Math.max(min, boundedMax) : boundedMax;
          onChange(boundedBoth);
        }
      }}
      rightSuffix={rightSuffix}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
      onBlur={() => setInputValue(`${value}`)}
    />
  );
};

export const FormInputNumber = wrapFormComponentHOC<number, InputNumberProps>(
  InputNumber,
);
