import { useWorkspace } from "../../../../hooks/useWorkspace.ts";
import { useAppMutation } from "../../../../http/useAppMutation.ts";
import { DeletionDialog } from "../components/DeletionDialog.tsx";
import type { Style } from "../types.ts";

export const StyleDeletionDialog = ({
  style,
  dialogOpen,
  onDialogChange,
  onCancel,
}: {
  style: Style;
  dialogOpen: boolean;
  onDialogChange: (dialogChange: boolean) => void;
  onCancel: () => void;
}) => {
  const { workspace } = useWorkspace();
  const deleteStyleMutation = useAppMutation({
    path: "styles/delete",
    invalidate: [`workspaces/${workspace.uuid}/styles`],
  }).mutation;

  return (
    <DeletionDialog
      title="Delete model"
      onDialogChange={onDialogChange}
      dialogOpen={dialogOpen}
      isLoading={deleteStyleMutation.isLoading}
      onClick={() => {
        deleteStyleMutation.mutate({
          uuid: style.uuid,
        });
      }}
      onCancel={onCancel}
    >
      <div className="flex-col-center gap-200 text-primary">
        <div className="flex-row-center gap-050">
          <span>Delete model:</span>
          <span className="label-md-semibold">
            {style.name !== "" ? style.name : "Untitled model"}
          </span>
        </div>
        <div>This model will not be accessible anymore.</div>
      </div>
    </DeletionDialog>
  );
};
