import { createStore } from "../../../../utils/createStore.ts";
import type { CreateTool, EditTool } from "../../types.ts";

export const lastSelectedToolStore = createStore(
  {
    lastCreateTool: undefined as CreateTool | undefined,
    lastEditTool: undefined as EditTool | undefined,
  },
  ({ setter }) => ({
    setLastCreateTool: setter("lastCreateTool"),
    setLastEditTool: setter("lastEditTool"),
  }),
);
