import { useAuth0 } from "@auth0/auth0-react";
import classNames from "classnames";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BaseButton } from "../../../../components/Button/BaseButton.tsx";
import { Button } from "../../../../components/Button/Button.tsx";
import { BaseDialog } from "../../../../components/Dialog/BaseDialog.tsx";
import { Icon } from "../../../../components/Icon/Icon.tsx";
import { CDNImage } from "../../../../components/Image/CDNImage.tsx";
import { useOnMount } from "../../../../hooks/useOnMount.ts";
import { useUser } from "../../../../hooks/useUser.ts";
import { useWorkspace } from "../../../../hooks/useWorkspace.ts";
import { useAppMutation } from "../../../../http/useAppMutation.ts";
import { useAppQuery } from "../../../../http/useAppQuery.ts";
import { BOARDS, GENERATE_TOOL_PATH, WORKSPACES } from "../../../../routes.ts";
import type { User } from "../../../../types.ts";
import {
  STYLE_TYPE_ICON_NAMES,
  STYLE_TYPE_LABELS,
} from "../../../Board/constants.ts";
import { GENERIC_STYLE_UUID } from "../../../constants.ts";
import {
  IMAGE_KIND_TO_ICON_NAMES,
  IMAGE_KIND_TO_LABELS,
  type Style,
} from "../../../types.ts";
import { useCreateBoard } from "../hooks/useCreateBoard.ts";
import { useStyles } from "../hooks/useStyles.ts";

export const UserInformationDialog = ({
  isOpen,
  onOpenChange,
}: {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}) => {
  const { user } = useUser();

  return (
    user && (
      <UserInformationDialogContent
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        user={user}
      />
    )
  );
};

const UserInformationDialogContent = ({
  isOpen,
  onOpenChange,
  user,
}: {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  user: User;
}) => {
  const { workspace } = useWorkspace();
  const navigate = useNavigate();
  const [occupation, setOccupation] = useState<string>();
  const [industry, setIndustry] = useState<string>();

  const onSelectStyleClick = (styleUuid: string) => {
    userInformationMutation.mutate({
      onboarding_finished: true,
    });
    createBoard({
      name: "Welcome project",
      styleUuid,
    });
  };

  const userInformationMutation = useAppMutation({
    path: "users/add-information",
    invalidate: ["users/me"],
  }).mutation;

  const [step, setStep] = useState<
    "personalInformation" | "industry" | "job" | "curatedStyleSelection"
  >("personalInformation");

  const { createBoard, isCreateBoardLoading } = useCreateBoard({
    onSuccess: (uuid) =>
      navigate(
        `${WORKSPACES}/${workspace.uuid}/${BOARDS}/${uuid}/${GENERATE_TOOL_PATH}`,
      ),
  });

  useOnMount(() => {
    if (
      user.first_name === null ||
      user.last_name === null ||
      user.terms_and_conditions_agreement === null
    ) {
      setStep("personalInformation");
    } else if (user.industry === null) {
      setStep("industry");
    } else if (user.occupation === null) {
      setStep("job");
    } else {
      setStep("curatedStyleSelection");
    }
  });

  return (
    <BaseDialog
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      displayCloseButton={false}
      overlayClassName="bg-surface-secondary-rest"
      className="max-w-[1080px] w-full border-0 bg-surface-primary-rest"
      content={
        step === "curatedStyleSelection" ? (
          <UserCuratedStyleSelectionForm
            key={step}
            onClick={onSelectStyleClick}
          />
        ) : step === "industry" ? (
          <UserInformationForm
            key={step}
            title="What best describes your industry?"
            options={INDUSTRY_OPTIONS}
            value={industry}
            onValueChange={setIndustry}
            onClick={() => {
              userInformationMutation.mutate({
                industry,
              });
              setStep("job");
            }}
          />
        ) : step === "job" ? (
          <UserInformationForm
            key={step}
            title="What best describes your job?"
            options={
              JOB_OPTIONS[
                industry && INDUSTRY_OPTIONS.includes(industry)
                  ? industry
                  : "Other"
              ]
            }
            value={occupation}
            onValueChange={setOccupation}
            isLoading={isCreateBoardLoading}
            onClick={() => {
              userInformationMutation.mutate({
                occupation,
              });
              setStep("curatedStyleSelection");
            }}
          />
        ) : (
          <PersonalInformationForm
            onClick={({
              firstName,
              lastName,
              phoneNumber,
            }: {
              firstName: string;
              lastName: string;
              phoneNumber: string;
            }) => {
              userInformationMutation.mutate({
                first_name: firstName,
                last_name: lastName,
                phone_number: phoneNumber === "" ? null : phoneNumber,
                terms_and_conditions_agreement: true,
              });
              setStep("industry");
            }}
          />
        )
      }
    />
  );
};

const PersonalInformationForm = ({
  onClick,
}: {
  onClick: ({
    firstName,
    lastName,
    phoneNumber,
  }: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
  }) => void;
}) => {
  // FIXME: This should be done in the backend during `login` mutation by direct auth0 http call:
  //  https://auth0.com/docs/api/authentication#get-user-info
  const { user: auth0User } = useAuth0();

  const [firstName, setFirstName] = useState<string>(
    auth0User?.given_name ?? "",
  );
  const [lastName, setLastName] = useState<string>(
    auth0User?.family_name ?? "",
  );
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const isFirstNameEditable = auth0User?.given_name === undefined;
  const isLastNameEditable = auth0User?.family_name === undefined;

  return (
    <div className="flex-col-center w-full px-2000 py-1200 gap-1000 text-primary">
      <span className="heading-2xl">Let's get started</span>
      <div className="flex-col gap-600 w-[270px]">
        <div className="flex-col gap-100">
          <span className="label-lg-semibold">First name*</span>
          <input
            disabled={!isFirstNameEditable}
            type="text"
            placeholder={isFirstNameEditable ? "Michael" : ""}
            className="w-full p-200 border rounded-100 body-lg-default text-primary placeholder:text-secondary hover:enabled:border-primary-hover focus:border-primary-hover"
            value={firstName}
            onChange={(event) => {
              setFirstName(event.target.value);
            }}
          />
        </div>
        <div className="flex-col gap-100">
          <span className="label-lg-semibold">Last name*</span>
          <input
            disabled={!isLastNameEditable}
            type="text"
            placeholder={isLastNameEditable ? "Scott" : ""}
            className="w-full p-200 border rounded-100 body-lg-default text-primary placeholder:text-secondary hover:enabled:border-primary-hover focus:border-primary-hover"
            value={lastName}
            onChange={(event) => {
              setLastName(event.target.value);
            }}
          />
        </div>
        <div className="flex-col gap-100">
          <span className="label-lg-semibold">Phone number (optional)</span>
          <input
            type="number"
            placeholder="0033692304950"
            className="w-full p-200 border rounded-100 body-lg-default text-primary placeholder:text-secondary hover:enabled:border-primary-hover focus:border-primary-hover hide-controls"
            value={phoneNumber}
            onChange={(event) => {
              setPhoneNumber(event.target.value);
            }}
          />
        </div>
        <div className="flex-col gap-200">
          <Button
            size="md"
            disabled={
              (isFirstNameEditable && firstName === "") ||
              (isLastNameEditable && lastName === "")
            }
            onClick={() => onClick({ firstName, lastName, phoneNumber })}
            className="label-lg-semibold"
          >
            Continue
          </Button>
          <span className="text-disabled body-md-default text-center">
            By clicking on continue, you agree to our&nbsp;
            <a
              href="https://gopimento.notion.site/Pimento-Terms-and-Conditions-2d2e2f4f6bf84854be7abfa506ffd71d"
              target="_blank"
            >
              <span className="underline text-inherit">
                Terms & Conditions and Privacy Policy
              </span>
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

const UserInformationForm = ({
  title,
  options,
  value,
  onValueChange,
  onClick,
  isLoading,
}: {
  title: string;
  options: string[];
  value: string | undefined;
  onValueChange: (value: string | undefined) => void;
  onClick: () => void;
  isLoading?: boolean;
}) => {
  const [other, setOther] = useState<string>("");

  return (
    <div className="flex-col-center w-full px-2000 py-1200 gap-1200 text-primary">
      <span className="heading-2xl text-center">{title}</span>
      <div className="grid grid-cols-2 gap-600 w-full">
        {options.map((label, id) => (
          <button
            key={id}
            type="button"
            className={classNames(
              "w-full h-1000 body-lg-default flex-row-center rounded-100 bg-input-surface-rest hover:bg-surface-primary-hover",
              {
                "border border-primary-active": value === label,
              },
            )}
            onClick={() => onValueChange(label)}
          >
            {label}
          </button>
        ))}
        <input
          type="text"
          className={classNames(
            "w-full h-1000 flex-row-center rounded-100 bg-input-surface-rest hover:bg-surface-primary-hover text-center body-lg-default text-primary placeholder:text-disabled focus:placeholder-transparent",
            {
              "border border-primary-active": other !== "" && value === other,
            },
          )}
          placeholder="Other"
          value={other}
          onChange={(event) => {
            setOther(event.target.value);
            onValueChange(
              event.target.value === "" ? undefined : event.target.value,
            );
          }}
          onFocus={() => {
            onValueChange(other === "" ? undefined : other);
          }}
        />
      </div>
      <BaseButton
        className="w-[50%] h-[44px] rounded-100 disabled:bg-surface-primary-active disabled:text-disabled hover:bg-surface-inverse-hover bg-surface-inverse-rest text-white flex-row-center label-lg-semibold"
        disabled={value === undefined}
        onClick={onClick}
        loading={isLoading}
      >
        Continue
      </BaseButton>
    </div>
  );
};

const UserCuratedStyleSelectionForm = ({
  onClick,
}: {
  onClick: (style_uuid: string) => void;
}) => {
  const { libraryStyles } = useStyles();
  if (!libraryStyles) {
    return null;
  } else if (libraryStyles.length === 0) {
    return null;
  }
  return (
    <div className="flex-col-center max-h-[calc(100vh-24px)] w-full px-2000 pt-1200 gap-1200 text-primary">
      <div className="flex-col-center gap-400">
        <div className="flex-row-center">
          <div className="heading-2xl text-primary"> Select the&nbsp;</div>
          <Icon
            name="Droplets"
            size="xl"
            className="stroke-caution-rest"
            strokeWidth="3"
          />
          <div className="heading-2xl text-caution-secondary">
            &nbsp;Style&nbsp;
          </div>
          <div className="heading-2xl text-primary">of your first image</div>
        </div>
        <div className="flex-col-center label-lg-default text-secondary">
          <div>
            Once you’re onboarded, you’ll have the opportunity to create your
            own!
          </div>
          <div className="flex-row items-center">
            <div>Don’t want to pick a style? You can&nbsp;</div>
            <BaseButton
              onClick={() => onClick(GENERIC_STYLE_UUID)}
              className="underline hover:opacity-80"
            >
              <div className="underline">skip this step →</div>
            </BaseButton>
          </div>
        </div>
      </div>
      <div className="flex-fill flex-col gap-400 w-full">
        <div className="label-lg-semibold text-secondary">
          Styles loved by Pimento users like you
        </div>
        <div className="flex-fill overflow-auto grid grid-cols-2 gap-200 w-full pb-1200">
          {libraryStyles
            .filter((style) => style.type === "style")
            .filter((style) => style.tags.includes("Popular"))
            .filter((style) => style.uuid !== GENERIC_STYLE_UUID)
            .slice(0, 6)
            .map((style, id) => (
              <StyleSummaryTile
                key={id}
                styleUuid={style.uuid}
                onClick={onClick}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

const StyleSummaryTile = ({
  styleUuid,
  onClick,
}: {
  styleUuid: string;
  onClick: (style_uuid: string) => void;
}) => {
  const { data: style } = useAppQuery<Style>({
    queryKey: `styles/${styleUuid}`,
    enabled: !!styleUuid,
  });
  if (!style) return null;
  return (
    <div className="w-full rounded-300 p-800 bg-surface-secondary-rest flex-row gap-400">
      <div className="grid grid-cols-2 gap-050">
        {style.training_images.slice(0, 4).map((image, index) => (
          <div
            key={index}
            className="w-[64px] h-[64px] rounded-200 bg-surface-primary-rest flex-row-center"
          >
            <CDNImage
              src={image.url}
              srcDimension="small128"
              imageClassName="w-[60px] h-[60px] rounded-150 object-cover object-center"
              alt={style.uuid}
            />
          </div>
        ))}
      </div>
      <div className="flex-col flex-fill justify-between">
        <div className="w-full flex-col gap-200">
          <div className="heading-xl text-primary">{style.name}</div>
          {style.type && (
            <div className="flex-row label-md-default text-primary">
              <div className="flex-row items-center gap-100">
                <Icon name={STYLE_TYPE_ICON_NAMES[style.type]} size="sm" />
                <div className="capitalize label-md-default">
                  {STYLE_TYPE_LABELS[style.type]}
                </div>
              </div>
              {style.images_kind && (
                <>
                  <Icon name="Dot" size="sm" />
                  <div className="flex-row items-center gap-100">
                    <Icon
                      name={IMAGE_KIND_TO_ICON_NAMES[style.images_kind]}
                      size="sm"
                    />
                    <div className="capitalize label-md-default">
                      {IMAGE_KIND_TO_LABELS[style.images_kind]}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <Button size="md" onClick={() => onClick(style.uuid)}>
          Select style
        </Button>
      </div>
    </div>
  );
};

const INDUSTRY_OPTIONS = [
  "Animation",
  "Branding, Marketing & Advertising",
  "Digital Creation & Art",
  "Fashion & Retail",
  "Gaming",
  "Interior Design & Architecture",
  "University or School",
  "Web & Product Design",
];

const JOB_OPTIONS: Record<(typeof INDUSTRY_OPTIONS)[number], string[]> = {
  Animation: [
    "3D Modeler",
    "Animator",
    "Character Designer",
    "Storyboard Artist",
    "Visual Effects Artist",
  ],
  "Branding, Marketing & Advertising": [
    "Brand Strategist / Manager",
    "Content Creator",
    "Copywriter",
    "Creative or Artistic Director",
    "Graphic Designer",
    "Illustrator",
    "Marketing Manager",
    "Product Manager",
    "Project Manager",
  ],
  "Digital Creation & Art": [
    "3D Artist",
    "AI Artist",
    "Content Creator",
    "Creative or Artistic Director",
    "Digital Designer",
    "Graphic Designer",
  ],
  "Fashion & Retail": [
    "Brand Manager",
    "Content Creator",
    "Creative or Artistic Director",
    "Fashion Designer",
    "Fashion Illustrator",
    "Stylist",
    "Textile Designer",
  ],
  Gaming: [
    "3D Designer",
    "Character Artist",
    "Creative or Artistic Director",
    "Game Designer, Artist",
    "Graphic Designer",
    "Storyboard Artist",
  ],
  "Interior Design & Architecture": [
    "Architect",
    "Creative Director",
    "Interior designer",
  ],
  "University or School": ["Professor", "Student"],
  "Web & Product Design": [
    "Graphic Designer",
    "Interaction Designer",
    "Product Designer",
    "UI Designer",
    "UX Designer",
    "Visual Designer",
  ],
  Other: [
    "AI Artist",
    "Content Creator",
    "Digital Creation & Art",
    "Digital Designer",
    "Graphic Designer",
    "3D Artist",
    "Architect",
  ],
};
