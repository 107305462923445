import { useMemo, useState } from "react";
import { Button } from "../../../components/Button/Button.tsx";
import { Dialog } from "../../../components/Dialog/Dialog.tsx";
import { useCheckIfStyleUseIsAllowed } from "../../../hooks/useCheckIfStyleUseIsAllowed.ts";
import { useStyles } from "../../Home/HomeIndex/hooks/useStyles.ts";
import {
  CUSTOM_TAG,
  POPULAR_TAG,
} from "../../Home/HomeIndex/Style/constants.ts";
import type { Style } from "../../Home/HomeIndex/types.ts";
import type { StyleType } from "../../types.ts";
import { StyleDescription } from "./StyleDescription.tsx";
import { StylePrimaryNavigation } from "./StylePrimaryNavigation.tsx";
import { StyleSecondaryNavigation } from "./StyleSecondaryNavigation.tsx";
import type { StylePickerNavigationFilter } from "./types.ts";

export const StylePicker = ({
  dialogOpen,
  onDialogChange,
  onStyleSelect,
  selectionButtonName,
  styleTypeFilter,
  initialSelectedStyleUuid,
  title = "Select model",
  disableNonMixableStyles = false,
}: {
  dialogOpen: boolean;
  onDialogChange: (dialogChange: boolean) => void;
  onStyleSelect?: (styleUuid: string) => void;
  selectionButtonName?: string;
  title?: string;
  styleTypeFilter?: StyleType;
  disableNonMixableStyles?: boolean;
  initialSelectedStyleUuid?: string;
}) => (
  <Dialog
    title={title}
    isOpen={dialogOpen}
    onOpenChange={(dialogChange) => {
      onDialogChange(dialogChange);
    }}
    className="max-w-[calc(100vw-24px)] max-h-[calc(100vh-24px)]"
    content={
      dialogOpen && (
        <StylePickerContent
          onStyleSubmit={onStyleSelect}
          selectionButtonName={selectionButtonName}
          styleTypeFilter={styleTypeFilter}
          disableNonMixableStyles={disableNonMixableStyles}
          initialSelectedStyleUuid={initialSelectedStyleUuid}
        />
      )
    }
  />
);

const StylePickerContent = ({
  onStyleSubmit,
  selectionButtonName = "Use model in new project",
  styleTypeFilter,
  disableNonMixableStyles,
  initialSelectedStyleUuid,
}: {
  onStyleSubmit?: (styleUuid: string) => void;
  selectionButtonName?: string;
  styleTypeFilter?: StyleType;
  disableNonMixableStyles: boolean;
  initialSelectedStyleUuid?: string;
}) => {
  const [selectedStyleUuid, setSelectedStyleUuid] = useState<
    string | undefined
  >(initialSelectedStyleUuid);
  const [stylePickerNavigationFilters, setStylePickerNavigationFilters] =
    useState<StylePickerNavigationFilter>({
      selectedTag: POPULAR_TAG,
      selectedStyleType: null,
      searchText: "",
      isTextSearchEnabled: true,
    });

  const { workspaceStyles, libraryStyles } = useStyles();

  const styles = useMemo(
    () => workspaceStyles?.concat(libraryStyles ?? []),
    [workspaceStyles, libraryStyles],
  );

  const nonDraftStyles = useMemo(
    () =>
      styles?.filter(
        (style: Style) =>
          ["training", "ready"].includes(style.status) &&
          (styleTypeFilter ? style.type === styleTypeFilter : true),
      ),
    [styles, styleTypeFilter],
  );

  const filteredStyles = useMemo(
    () =>
      stylePickerNavigationFilters.isTextSearchEnabled &&
      !stylePickerNavigationFilters.searchText.isBlank()
        ? nonDraftStyles?.filter(
            (style: Style) =>
              style.name
                ?.toLowerCase()
                .includes(
                  stylePickerNavigationFilters.searchText.toLowerCase(),
                ),
          )
        : nonDraftStyles?.filter(
            (style: Style) =>
              (stylePickerNavigationFilters.selectedStyleType
                ? style.type === stylePickerNavigationFilters.selectedStyleType
                : true) &&
              (stylePickerNavigationFilters.selectedTag === CUSTOM_TAG
                ? !style.is_curated
                : stylePickerNavigationFilters.selectedTag
                ? style.tags.includes(stylePickerNavigationFilters.selectedTag)
                : true),
          ),
    [nonDraftStyles, stylePickerNavigationFilters],
  );

  const selectedStyle = useMemo(
    () => styles?.find((it) => it.uuid === selectedStyleUuid),
    [styles, selectedStyleUuid],
  );

  const isStyleUseAllowed = useCheckIfStyleUseIsAllowed(selectedStyle);

  return (
    <div className="h-[680px] flex-fill flex-col text-primary">
      {styles && (
        <div className="flex-fill flex-row">
          <StylePrimaryNavigation
            styles={nonDraftStyles ?? []}
            styleTypeFilter={styleTypeFilter}
            stylePickerNavigationFilters={stylePickerNavigationFilters}
            setStylePickerNavigationFilters={setStylePickerNavigationFilters}
            initialSelectedStyleUuid={initialSelectedStyleUuid}
          />
          <StyleSecondaryNavigation
            styles={filteredStyles ?? []}
            onStyleUuidSelect={setSelectedStyleUuid}
            selectedStyleUuid={selectedStyleUuid}
            searchText={stylePickerNavigationFilters.searchText}
            initialSelectedStyleUuid={initialSelectedStyleUuid}
          />
          <div className="flex-col flex-fill w-[480px] justify-end">
            {selectedStyleUuid && (
              <StyleDescription styleUuid={selectedStyleUuid} />
            )}
            <div className="flex-row w-full py-300 px-400 gap-200 items-center justify-end border-t">
              <Button
                onClick={() => {
                  if (selectedStyleUuid) onStyleSubmit?.(selectedStyleUuid);
                }}
                disabled={
                  !isStyleUseAllowed ||
                  (selectedStyle?.status !== "ready" || disableNonMixableStyles
                    ? !selectedStyle?.is_mixable
                    : false)
                }
                size="md"
                variant="primary"
              >
                {selectionButtonName}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
