import Intercom from "@intercom/messenger-js-sdk";
import { getConfig } from "./environment.ts";

export const initIntercom = ({
  userUuid,
  userEmail,
}: {
  userUuid: string;
  userEmail: string;
}) => {
  // XXX: We use same testing workspace between dev and staging as Intercom allows a single one
  const appId = getConfig({
    dev: "d5mxodf2",
    staging: "d5mxodf2",
    prod: null,
  });
  // FIXME: We disable intercom for now on production until we completely finalized testing all features and decided to
  //  switch to this solution. To enable Intercom on production, just replace prod value from `null` to `q1mqyn3x`
  //  (official workspace)
  if (appId === null) return;

  Intercom({
    app_id: appId,
    user_id: userUuid,
    email: userEmail,
    // FIXME: To improve customer tracking on Intercom, we may add the following fields `name` and `created_at` and
    //  fill them with database information
  });
};
