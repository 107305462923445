export type User = {
  uuid: string;
  permissions: UserPermission[];
  workspaces: Workspace[];

  first_name: string | null;
  last_name: string | null;
  email: string;
  phone_number: string | null;

  occupation: string | null;
  industry: string | null;
  heard_about_us: string | null;
  terms_and_conditions_agreement: boolean | null;

  onboarding_finished_at: string | null;
};

export type Workspace = {
  uuid: string;
  name: string;
};

export type UserWorkspace = {
  uuid: string;
  first_name: string | null;
  last_name: string | null;
  email: string;
  is_owner: boolean;
};

export type WorkspaceDetails = {
  uuid: string;
  name: string;
  type: "basic" | "free_standard" | "free_pro" | "standard" | "pro";
  num_members: number;
  num_seats: number | null;
  users: UserWorkspace[];
  total_credits_remaining_amount: number;
  subscription_credits_remaining_amount: number;
  subscription_credits_next_added_at: string | null;
};

export const isUserAllowedTo = (user: User, permission: UserPermission) =>
  user.permissions.includes(permission);

export type UserPermission =
  | "mode:debug"
  | "style-tags:create"
  | "styles:add-tags"
  | "styles:copy"
  | "styles:curate"
  | "styles:delete-any"
  | "styles:remove-tags"
  | "styles:update-curated-styles"
  | "subscription:upgrade:hidden"
  | "subscription:view"
  | "styles:generate-with-custom-styles";

export type WidenLiteral<T> = T extends string
  ? string
  : T extends number
  ? number
  : T;

// Hacky version of Parameters for overloads
// Inspired by various comment on https://github.com/microsoft/TypeScript/issues/32164
export type ParametersOverloads<T> = T extends {
  (...args: infer A1): unknown;
  (...args: infer A2): unknown;
  (...args: infer A3): unknown;
  (...args: infer A4): unknown;
}
  ? A1 | A2 | A3 | A4
  : T extends {
      (...args: infer A1): unknown;
      (...args: infer A2): unknown;
      (...args: infer A3): unknown;
    }
  ? A1 | A2 | A3
  : T extends { (...args: infer A1): unknown; (...args: infer A2): unknown }
  ? A1 | A2
  : T extends { (...args: infer A1): unknown }
  ? A1
  : never;

export type Nullable<T> = T | null | undefined;

export type UnionMatcher<Union, Match> = Union extends Match ? Union : never;

export type KeysMatching<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];

export type DistributiveOmit<T, K extends keyof any> = T extends any
  ? Omit<T, K>
  : never;

export type UnionToIntersection<T> = (
  T extends any ? (x: T) => any : never
) extends (x: infer R) => any
  ? R
  : never;

export type WithRequiredFields<
  TObj extends Record<string, unknown>,
  TKeys extends keyof TObj,
> = Required<Pick<TObj, TKeys>> & Omit<TObj, TKeys>;

export type WithOptionalFields<
  TObj extends Record<string, unknown>,
  TKeys extends keyof TObj,
> = Partial<Pick<TObj, TKeys>> & Omit<TObj, TKeys>;

export type Seconds = number;
