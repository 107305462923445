import classNames from "classnames";
import {
  AppWindow,
  ArrowBigUp,
  ArrowDownToLine,
  ArrowLeft,
  ArrowLeftRight,
  ArrowRight,
  ArrowRightToLine,
  ArrowUpRight,
  ArrowUpRightSquare,
  ArrowUpToLine,
  BadgeCheck,
  BadgeInfo,
  BrainCircuit,
  BrickWall,
  Brush,
  Building,
  Camera,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  ChevronsUpDown,
  ChevronUp,
  Circle,
  ClipboardPaste,
  ClipboardType,
  Clock,
  Command,
  Compass,
  Cone,
  Copy,
  CopyPlus,
  CreditCard,
  Dices,
  Dot,
  Download,
  Droplets,
  Ellipsis,
  Eraser,
  Expand,
  ExternalLink,
  FilePlus,
  Flame,
  FlaskConical,
  Focus,
  Frame,
  Gauge,
  Grid3x3,
  Hash,
  Heart,
  HelpCircle,
  Home,
  Image,
  ImageOff,
  ImagePlus,
  Info,
  LampCeiling,
  LampDesk,
  Lasso,
  LassoSelect,
  Layers,
  Library,
  LifeBuoy,
  Lightbulb,
  Link,
  Loader,
  LoaderCircle,
  Lock,
  LogOut,
  Maximize,
  Menu,
  Minus,
  MoreHorizontal,
  MoreVertical,
  MousePointer,
  MousePointer2,
  MoveLeft,
  Paintbrush,
  Palette,
  Pen,
  Pencil,
  PencilLine,
  PersonStanding,
  Pin,
  Pipette,
  PlayCircle,
  Plus,
  PlusCircle,
  Ratio,
  Redo,
  RefreshCcw,
  Save,
  ScissorsSquare,
  ScrollText,
  Search,
  Send,
  Settings,
  Settings2,
  Shirt,
  ShoppingBasket,
  SlidersHorizontal,
  Sparkles,
  SquareArrowOutUpRight,
  SquarePlus,
  Star,
  StopCircle,
  Tag,
  Target,
  Text,
  ThumbsDown,
  ThumbsUp,
  Timer,
  Trash,
  Triangle,
  TriangleAlert,
  Type,
  Undo,
  Upload,
  UploadCloud,
  User,
  UserRound,
  Video,
  WandSparkles,
  X,
} from "lucide-react";
import type { SVGProps } from "react";
// FIXME: Not possible to resize the following icons, only in fixed sizes
// Need to check which ones are still used and if they are not supported by lucide-react
import { Tooltip, type TooltipProps } from "../Tooltip/Tooltip.tsx";
import { Assets } from "./assets/Assets.tsx";
import { Bolt } from "./assets/Bolt.tsx";
import { Discord } from "./assets/Discord.tsx";
import { Draw } from "./assets/Draw.tsx";
import { ImageSlider } from "./assets/ImageSlider.tsx";
import { Invalid } from "./assets/Invalid.tsx";
import { OnboardingCenteredTip } from "./assets/OnboardingCenteredTip.tsx";
import { OnboardingCharacterAngle1 } from "./assets/OnboardingCharacterAngle1.tsx";
import { OnboardingCharacterAngle2 } from "./assets/OnboardingCharacterAngle2.tsx";
import { OnboardingHorizontalFormat } from "./assets/OnboardingHorizontalFormat.tsx";
import { OnboardingNonCenteredTip } from "./assets/OnboardingNonCenteredTip.tsx";
import { OnboardingNoTextTip } from "./assets/OnboardingNoTextTip.tsx";
import { OnboardingObjectAngle1 } from "./assets/OnboardingObjectAngle1.tsx";
import { OnboardingObjectAngle2 } from "./assets/OnboardingObjectAngle2.tsx";
import { OnboardingSizeTip } from "./assets/OnboardingSizeTip.tsx";
import { OnboardingSquareFormat } from "./assets/OnboardingSquareFormat.tsx";
import { OnboardingVerticalFormat } from "./assets/OnboardingVerticalFormat.tsx";
import { Pimento } from "./assets/Pimento.tsx";
import { PimentoHome } from "./assets/PimentoHome.tsx";
import { PimentoStar } from "./assets/PimentoStar.tsx";
import { RectangleHorizontal } from "./assets/RectangleHorizontal.tsx";
import { RectangleVertical } from "./assets/RectangleVertical.tsx";
import { RemoveBackground } from "./assets/RemoveBackground.tsx";
import { Select } from "./assets/Select.tsx";
import { Square } from "./assets/Square.tsx";
import { Valid } from "./assets/Valid.tsx";
// https://lucide.dev/icons/
const IconsMap = {
  AppWindow,
  ArrowBigUp,
  ArrowDownToLine,
  ArrowLeft,
  ArrowLeftRight,
  ArrowRight,
  ArrowUpRight,
  ArrowUpRightSquare,
  ArrowUpToLine,
  ArrowRightToLine,
  Assets,
  BadgeCheck,
  BadgeInfo,
  Bolt,
  BrainCircuit,
  BrickWall,
  Brush,
  Building,
  Camera,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronsLeft,
  ChevronRight,
  ChevronUp,
  ChevronsUpDown,
  ChevronsRight,
  ClipboardPaste,
  ClipboardType,
  Clock,
  Circle,
  Command,
  Compass,
  Cone,
  Copy,
  CopyPlus,
  CreditCard,
  Dices,
  Discord,
  Dot,
  Draw,
  Droplets,
  Download,
  Ellipsis,
  Eraser,
  Expand,
  ExternalLink,
  FilePlus,
  Flame,
  Focus,
  Frame,
  FlaskConical,
  Gauge,
  Grid3x3,
  Hash,
  Heart,
  HelpCircle,
  Home,
  PersonStanding,
  Pimento,
  PimentoHome,
  PimentoStar,
  Image,
  ImageOff,
  ImagePlus,
  ImageSlider,
  Info,
  Invalid,
  LampCeiling,
  LampDesk,
  Lasso,
  LassoSelect,
  Layers,
  Loader,
  LoaderCircle,
  Lock,
  LogOut,
  Library,
  Lightbulb,
  Link,
  Maximize,
  Menu,
  Minus,
  MoreHorizontal,
  MoreVertical,
  MousePointer,
  MousePointer2,
  MoveLeft,
  OnboardingCenteredTip,
  OnboardingCharacterAngle1,
  OnboardingCharacterAngle2,
  OnboardingHorizontalFormat,
  OnboardingNonCenteredTip,
  OnboardingNoTextTip,
  OnboardingObjectAngle1,
  OnboardingObjectAngle2,
  OnboardingSizeTip,
  OnboardingSquareFormat,
  OnboardingVerticalFormat,
  LifeBuoy,
  Paintbrush,
  Palette,
  Pin,
  Pipette,
  Plus,
  Pen,
  Pencil,
  PencilLine,
  PlayCircle,
  PlusCircle,
  Ratio,
  RectangleHorizontal,
  RectangleVertical,
  Redo,
  RefreshCcw,
  RemoveBackground,
  Save,
  ScissorsSquare,
  ScrollText,
  Search,
  Select,
  Send,
  Settings,
  Settings2,
  Shirt,
  ShoppingBasket,
  SlidersHorizontal,
  Square,
  SquarePlus,
  SquareArrowOutUpRight,
  Star,
  StopCircle,
  Sparkles,
  Tag,
  Target,
  Text,
  ThumbsDown,
  ThumbsUp,
  Timer,
  Trash,
  Triangle,
  TriangleAlert,
  Type,
  Undo,
  Upload,
  UploadCloud,
  User,
  UserRound,
  Valid,
  Video,
  WandSparkles,
  X,
};
export type IconName = keyof typeof IconsMap;
export type IconSize = keyof typeof iconSizeToSize;
export type IconProps = {
  name: IconName;
  size?: IconSize;
  tooltip?: Omit<TooltipProps, "children">;
  rotate?: number;
  className?: string;
} & Omit<SVGProps<SVGSVGElement>, "height" | "width" | "ref">;

export const Icon = ({
  name,
  size = "md",
  tooltip,
  rotate,
  style,
  className,
  ...rest
}: IconProps) => {
  const Comp = IconsMap[name];
  const iconProps = {
    style: {
      transform: rotate ? `rotate(${rotate}deg)` : undefined,
      ...style,
    },
    size: iconSizeToSize[size],
    ...rest,
  };
  if (tooltip) {
    return (
      <Tooltip
        {...{
          ...tooltip,
          className: classNames("max-w-[250px]", tooltip.className),
        }}
      >
        <Comp {...iconProps} className={classNames(strokeClass, className)} />
      </Tooltip>
    );
  }
  return <Comp {...iconProps} className={classNames(strokeClass, className)} />;
};

const iconSizeToSize = {
  xs: 12,
  sm: 16,
  md: 20,
  lg: 24,
  xl: 28,
} as const;

const strokeClass = "stroke-lg";
