import { useEffect, useRef, useState } from "react";
import { z } from "zod";
import { Form } from "../../../../../../components/Form/Form.tsx";
import { useForm } from "../../../../../../components/Form/FormContext.ts";
import { useUser } from "../../../../../../hooks/useUser.ts";
import { useAppQuery } from "../../../../../../http/useAppQuery.ts";
import { isUserAllowedTo } from "../../../../../../types.ts";
import { addNullInput } from "../../../../../../utils/validations.ts";
import type { Style } from "../../../../../types.ts";
import { useSelectedStylesGenerationParams } from "../../../../hooks/useSelectedStylesGenerationParams.ts";
import type { ImageContent } from "../../../../types.ts";
import { GenerationBar } from "../../../components/GenerationBar/GenerationBar.tsx";
import { CollapsableSettingsSectionWrapper } from "../../../components/SettingsMenu/CollapsableSettingsSectionWrapper.tsx";
import { MlModelArchitectureSection } from "../../../components/SettingsMenu/MlModelArchitectureSection.tsx";
import { QualityPresetSection } from "../../../components/SettingsMenu/QualityPresetSection.tsx";
import { SettingsSectionWrapper } from "../../../components/SettingsMenu/SettingsSectionWrapper.tsx";
import { SliderSection } from "../../../components/SettingsMenu/SliderSection.tsx";
import { SwitchSection } from "../../../components/SettingsMenu/SwitchSection.tsx";
import { SettingsMenuLayout } from "../../../components/SettingsMenuLayout.tsx";
import { scrollCreateWorkspaceGridToTop } from "../../utils/scrollCreateWorkspaceGridToTop.ts";
import { useStyleTransferGeneration } from "./hooks/useStyleTransferMutation.ts";
import { useStyleTransferSettings } from "./hooks/useStyleTransferSettings.ts";
import { InitialImageWrappedSection } from "./InitialImageWrappedSection.tsx";
import { InitImageColorInfluenceSection } from "./InitImageColorInfluenceSection.tsx";
import { styleTransferBaseImageStore } from "./stores/styleTransferBaseImageStore.ts";
import { transferPromptStore } from "./stores/transferPromptStore.ts";

export const StyleTransferSettings = () => {
  const { mutation: transferStyle, isLoading } = useStyleTransferGeneration({
    onSuccess: scrollCreateWorkspaceGridToTop,
  });

  return (
    <Form
      className="flex-col flex-fill"
      schema={zStyleTransferSettings}
      initialValues={{
        styleTransferBaseImageUuid: "",
        prompt: "",
        selectedStyleUuid: null,
        areMultipleStylesSelected: false,
      }}
      onSubmit={(values) => {
        // FIXME: make the mutation async
        transferStyle({
          prompt: values.prompt,
          initImageUuid: values.styleTransferBaseImageUuid,
        });
        return Promise.resolve(true);
      }}
    >
      <StyleTransferSettingsFormContent isGenerationLoading={isLoading} />
    </Form>
  );
};

const zStyleTransferSettings = z.object({
  styleTransferBaseImageUuid: z
    .string({ required_error: "Please add an initial image" })
    .uuid(),

  selectedStyleUuid: addNullInput(z.string().uuid(), "Please select a model"),
  areMultipleStylesSelected: z.boolean().refine((val) => !val, {
    message: "Multiple model not available with this tool",
  }),
  prompt: z.string().min(1, { message: "Please enter a description" }),
});
type StyleTransferSettingsValues = z.input<typeof zStyleTransferSettings>;

const StyleTransferSettingsFormContent = ({
  isGenerationLoading,
}: {
  isGenerationLoading: boolean;
}) => {
  const { styleTransferSettings, setStyleTransferSettings } =
    useStyleTransferSettings();
  const { selectedStylesGenerationParams } =
    useSelectedStylesGenerationParams();
  const { prompt } = transferPromptStore.useState();
  const { styleTransferBaseImageUuid } = styleTransferBaseImageStore.useState();
  const { user } = useUser();

  const { data: style } = useAppQuery<Style>({
    queryKey: selectedStylesGenerationParams.length
      ? `styles/${selectedStylesGenerationParams[0].uuid}`
      : null,
    enabled: !!selectedStylesGenerationParams.length,
  });

  const [advancedSectionOpen, setAdvancedSectionOpen] = useState(false);

  const { data: initImage } = useAppQuery<ImageContent>({
    queryKey: styleTransferBaseImageUuid
      ? `contents/${styleTransferBaseImageUuid}`
      : null,
  });

  const { setValues, submit, useError } =
    useForm<StyleTransferSettingsValues>();
  const missingStyleError = useError((v) => v.selectedStyleUuid);
  const areMultipleStylesSelectedError = useError(
    (v) => v.areMultipleStylesSelected,
  );

  useEffect(() => {
    setValues({ prompt });
  }, [setValues, prompt]);

  useEffect(() => {
    setValues({
      selectedStyleUuid: selectedStylesGenerationParams.length
        ? selectedStylesGenerationParams[0].uuid
        : null,
      areMultipleStylesSelected: selectedStylesGenerationParams.length > 1,
    });
  }, [setValues, selectedStylesGenerationParams]);

  useEffect(() => {
    setValues({ styleTransferBaseImageUuid });
  }, [setValues, styleTransferBaseImageUuid]);

  const previousImageUuid = useRef<string>();
  const [isWaitingForDescription, setIsWaitingForDescription] = useState(false);

  useEffect(() => {
    // XXX: the description and its loading state updates differently if the user selects another image.
    // To detect if the user has changed the selected image we use a ref previousImageUuid.
    // Case where the user is still on the same image:
    if (previousImageUuid.current === initImage?.uuid) {
      if (isWaitingForDescription && initImage?.description) {
        transferPromptStore.setPrompt(initImage.description);
        setIsWaitingForDescription(false);
      }
    }
    // Case where the user selects another image:
    else {
      previousImageUuid.current = initImage?.uuid;
      if (initImage?.description) {
        setIsWaitingForDescription(false);
      } else if (!initImage?.uuid) {
        setIsWaitingForDescription(false);
      } else {
        setIsWaitingForDescription(true);
      }
    }
  }, [
    initImage?.description,
    initImage?.uuid,
    isWaitingForDescription,
    setValues,
  ]);

  return (
    <SettingsMenuLayout
      body={
        <div className="flex-col">
          <InitialImageWrappedSection
            imageUuid={styleTransferBaseImageUuid}
            onImageUuidChange={(uuid) =>
              styleTransferBaseImageStore.setStyleTransferBaseImage({
                styleTransferBaseImageUuid: uuid,
              })
            }
            error={useError((v) => v.styleTransferBaseImageUuid)}
          />
          <SettingsSectionWrapper
            name="Input type"
            content={<InitImageColorInfluenceSection />}
          />
          <CollapsableSettingsSectionWrapper
            name="Advanced"
            open={advancedSectionOpen}
            onOpenChange={setAdvancedSectionOpen}
            content={
              <div className="flex-col">
                <SliderSection
                  min={0}
                  max={100}
                  value={Math.round(
                    styleTransferSettings.structure_strength * 100,
                  )}
                  onChange={(value) =>
                    setStyleTransferSettings({
                      structure_strength: value / 100,
                    })
                  }
                  sliderName="Input structure"
                  sliderInformationSection={
                    <div className="flex-col gap-400 text-primary body-md-default">
                      <span>
                        <span className="body-md-semibold">
                          Input structure
                        </span>{" "}
                        - The higher this parameter, the more the structure and
                        shapes of your initial image will be taken into account.
                      </span>
                    </div>
                  }
                />
                <SliderSection
                  min={0}
                  max={100}
                  value={Math.round(styleTransferSettings.creativity * 100)}
                  onChange={(value) => {
                    setStyleTransferSettings({
                      creativity: value / 100,
                    });
                  }}
                  sliderName="Creativity"
                  sliderInformationSection={
                    <div className="flex-col py-100 gap-400 text-primary body-md-default">
                      <div>
                        <span className="body-md-semibold">Creativity</span> -
                        The higher this parameter, the more diversity you will
                        have in the generated images.
                      </div>
                    </div>
                  }
                />
                {styleTransferSettings.init_image_color_influence ===
                  "color" && (
                  <SliderSection
                    min={0}
                    max={100}
                    value={Math.round(
                      styleTransferSettings.colors_strength * 100,
                    )}
                    onChange={(inputColorPercentage) =>
                      setStyleTransferSettings({
                        colors_strength: inputColorPercentage / 100,
                        init_image_color_influence: "color",
                      })
                    }
                    sliderName="Input colors"
                    sliderInformationSection={
                      <div className="flex-col gap-400 text-primary body-md-default">
                        <div>
                          <span className="body-md-semibold">Input colors</span>{" "}
                          - The higher this parameter, the more the colors of
                          your input image will be taken into account. Select 0%
                          if you don’t want the colors of your image input to
                          influence your results.
                        </div>
                      </div>
                    }
                  />
                )}
                {/* FIXME: This option is temporary until we full remove SDXL from training and the app, to be able to use old model if required by clients*/}
                {style?.ml_model_architectures.includes("sdxl") &&
                  selectedStylesGenerationParams.length === 1 && (
                    <SwitchSection
                      switchName="New Model"
                      switchInformationSection="Toggle to use latest Pimento styles"
                      value={
                        styleTransferSettings.ml_model_architecture !== "sdxl"
                      }
                      onChange={(enable) =>
                        setStyleTransferSettings({
                          ml_model_architecture: enable ? undefined : "sdxl",
                        })
                      }
                      isBeta
                    />
                  )}
              </div>
            }
          />
          {user && isUserAllowedTo(user, "mode:debug") && (
            <div className="border-t">
              <CollapsableSettingsSectionWrapper
                name="Debug mode"
                content={
                  <div className="flex-col gap-200">
                    {/* FIXME: We currently display this option only for photographic flux style as we didn't test it for other type of styles. This can be extended in a second phase. */}
                    {style?.images_kind === "photography" &&
                      style.ml_model_architectures.includes("flux") && (
                        <SwitchSection
                          switchName="Style Enhancement"
                          switchInformationSection="Activate it to better catch your style details such as colors ... This is still experimental and can lead to some prompt inconsistencies."
                          value={
                            styleTransferSettings.enable_flux_prompt_enhancement
                          }
                          onChange={(enable) => {
                            setStyleTransferSettings({
                              enable_flux_prompt_enhancement: enable,
                            });
                          }}
                          isBeta
                        />
                      )}
                    <MlModelArchitectureSection
                      style={style}
                      mlModelArchitecture={
                        styleTransferSettings.ml_model_architecture
                      }
                      onClickMlModelArchitecture={(mlModelArchitecture) =>
                        setStyleTransferSettings({
                          ml_model_architecture: mlModelArchitecture,
                        })
                      }
                    />
                    <QualityPresetSection
                      value={styleTransferSettings.quality_preset}
                      onChange={(preset) =>
                        setStyleTransferSettings({ quality_preset: preset })
                      }
                    />
                  </div>
                }
              />
            </div>
          )}
        </div>
      }
      footer={
        <GenerationBar
          onGenerate={submit}
          isLoading={isGenerationLoading}
          prompt={prompt}
          setPrompt={(newPrompt) => {
            transferPromptStore.setPrompt(newPrompt);
          }}
          promptError={useError((v) => v.prompt)}
          isPromptLoading={isWaitingForDescription}
          onPromptLoadingChange={(loading) => {
            setIsWaitingForDescription(loading);
            if (!loading) transferPromptStore.setPrompt("");
          }}
          sectionTitle="Image description"
          buttonContent="Transfer style"
          autoFocus
          extraErrors={[
            missingStyleError,
            areMultipleStylesSelectedError,
          ].filter((error): error is string => error !== undefined)}
        />
      }
    />
  );
};
