import { useNavigate } from "react-router-dom";
import { useWorkspace } from "../../../../hooks/useWorkspace.ts";
import { useAppMutation } from "../../../../http/useAppMutation.ts";
import { STYLES, WORKSPACES } from "../../../../routes.ts";
import type { StyleType } from "../../../types.ts";

export const useCreateStyle = () => {
  const { workspace } = useWorkspace();
  const { mutate, isLoading } = useAppMutation({
    path: `workspaces/${workspace.uuid}/styles`,
    invalidate: [`workspaces/${workspace.uuid}/styles`],
    waitForInvalidation: false,
  }).mutation;

  const navigate = useNavigate();

  return {
    createStyle: ({ type = null }: { type?: StyleType | null } = {}) => {
      mutate(
        { name: "", type },
        {
          onSuccess: ({ data }: { data: { uuid: string } }) => {
            navigate(`${WORKSPACES}/${workspace.uuid}/${STYLES}/${data.uuid}`);
          },
        },
      );
    },
    isCreateStyleLoading: isLoading,
  };
};
