import classNames from "classnames";
import styles from "./spinner.module.css";

export const Spinner = ({
  className,
  size = "md",
}: {
  className?: string;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
}) => (
  <span
    role="status"
    className={classNames(styles["spinner"], styles[size], className)}
  />
);
