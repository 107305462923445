import classNames from "classnames";
import { useState } from "react";
import { Button } from "../Button/Button.tsx";
import type { IconName } from "../Icon/Icon.tsx";
import { Popover } from "../Popover/Popover.tsx";

export const SelectionDropdown = <T extends string>({
  value,
  onChange,
  options,
  size,
  buttonClassName,
}: {
  value: T;
  onChange: (value: T) => void;
  options: { value: T; label: string; iconName: IconName }[];
  size: "sm" | "md" | "lg";
  buttonClassName?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedOption = options.find((option) => option.value === value);

  return (
    <Popover
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      align="start"
      side="bottom"
      sideOffset={4}
      className="p-200"
      content={
        <div className="flex-col">
          {options.map((option) => (
            <Button
              key={option.value}
              variant="tertiary"
              size={size}
              onClick={() => onChange(option.value)}
              iconName={option.iconName}
              className={classNames(
                "justify-between",
                option.value === value
                  ? "bg-button-surface-tertiary-active"
                  : undefined,
              )}
            >
              {option.label}
            </Button>
          ))}
        </div>
      }
    >
      <Button
        variant="secondary"
        iconName={selectedOption?.iconName}
        size={size}
        className={classNames(
          "justify-between",
          isOpen ? "bg-button-surface-tertiary-active" : undefined,
          buttonClassName,
        )}
        disclosure
      >
        {selectedOption?.label}
      </Button>
    </Popover>
  );
};
