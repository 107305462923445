import { useMemo } from "react";
import { useWorkspace } from "../../hooks/useWorkspace.ts";

export const RemainingCreditMessage = ({ cost }: { cost: number }) => {
  const { workspace } = useWorkspace();

  const nextBalanceUpdateInDays = useMemo(
    () =>
      workspace.subscription_credits_next_added_at
        ? Math.floor(
            (new Date(workspace.subscription_credits_next_added_at).getTime() -
              new Date().getTime()) /
              (1000 * 60 * 60 * 24),
          )
        : undefined,
    [workspace.subscription_credits_next_added_at],
  );

  return workspace.total_credits_remaining_amount < cost ? (
    <div className="body-md-default text-error-secondary">
      {cost} credits needed. {workspace.total_credits_remaining_amount} credits
      left
      {nextBalanceUpdateInDays
        ? `. Next balance update in ${nextBalanceUpdateInDays} days`
        : ""}
    </div>
  ) : (
    <div className="body-md-default text-secondary">
      {workspace.total_credits_remaining_amount} credits remaining
    </div>
  );
};
