import classNames from "classnames";
import { Animation } from "../../../components/Animation/Animation.tsx";
import { NavigationButton } from "../../../components/Button/NavigationButton.tsx";
import { ModelCreationCloseButton } from "../components/ModelCreationCloseButton.tsx";

export const StyleInitSplashScreen = ({
  widthClassName,
}: {
  widthClassName: string;
}) => (
  <div className="relative flex-col-center flex-fill w-full overflow-y-auto text-primary">
    <ModelCreationCloseButton className="absolute top-400 right-400" />
    <div
      className={classNames(
        "flex-fill w-full flex-col pt-2000 pb-800 gap-1000 items-center",
        widthClassName,
      )}
    >
      <div className="flex-col items-center">
        <span className="heading-2xl">Model in creation...</span>
        <span className="body-lg-default text-secondary">
          It takes on average 15 minutes. You'll be notified by email.
        </span>
        <div
          style={{ height: 327, width: 324 }}
          className="flex-fill flex-col-center"
        >
          <Animation
            loadData={() => import("../../../assets/boardInitLoading.json")}
            loop
            autoplay
          />
        </div>
      </div>
      <NavigationButton to="/">Back to home</NavigationButton>
    </div>
  </div>
);
