import { useEffect, useState } from "react";
import { Progress } from "../../../components/Progress/Progress.tsx";
import type { Seconds } from "../../../types.ts";
import { computeDateDifference } from "../../../utils/date.ts";
import type {
  AssetEditionContent,
  ImageContent,
  ImageGenerationModelType,
} from "../types.ts";

const ESTIMATED_GENERATION_TIME_MAPPING: {
  [key in ImageGenerationModelType | "default"]: Seconds;
} = {
  text_to_image: 15,
  image_to_image: 15,
  generative_fill: 15,
  style_transfer: 20,
  brief_to_image: 25,
  upscaler: 45,
  default: 30,
};
const MAX_GENERATION_LOADING_PROGRESS = 0.95;

const getEstimatedGenerationTime = (
  image: ImageContent | AssetEditionContent,
) => {
  if (
    image.generation_model_type === "text_to_image" ||
    image.generation_model_type === "image_to_image" ||
    image.generation_model_type === "generative_fill" ||
    image.generation_model_type === "style_transfer" ||
    image.generation_model_type === "brief_to_image"
  ) {
    // FIXME: quality params high is currently only accessible in debug mode.
    //  To avoid overloading the queries as history we are not going to send the
    //  generation params that allows to know if the generation was in quality
    //  high. Readd the logic if the quality high params is available to all the
    //  users again and we want to have more accurate loaders.
    return ESTIMATED_GENERATION_TIME_MAPPING[image.generation_model_type];
  }
  return image.generation_model_type &&
    Object.keys(ESTIMATED_GENERATION_TIME_MAPPING).includes(
      image.generation_model_type,
    )
    ? ESTIMATED_GENERATION_TIME_MAPPING[image.generation_model_type]
    : ESTIMATED_GENERATION_TIME_MAPPING.default;
};

export const ImageGenerationProgress = ({
  image,
}: {
  image: ImageContent | AssetEditionContent;
}) => {
  const [secondsSinceCreation, setSecondsSinceCreation] = useState(0);

  const estimatedGenerationTime = getEstimatedGenerationTime(image);

  useEffect(() => {
    const creationDate = new Date(image.created_at);
    const initialDifference = computeDateDifference(creationDate, new Date());
    setSecondsSinceCreation(initialDifference);

    // XXX: don't trigger a setInterval if generation is longer that estimated time because no progress will happen
    if (initialDifference < estimatedGenerationTime) {
      const intervalId = setInterval(() => {
        setSecondsSinceCreation(
          computeDateDifference(creationDate, new Date()),
        );
      }, 1000);
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Progress
      value={Math.min(
        secondsSinceCreation / estimatedGenerationTime,
        MAX_GENERATION_LOADING_PROGRESS,
      )}
      rootClassName="!bg-surface-primary-hover rounded-200"
      indicatorClassName="animate-pulse"
    />
  );
};
