import { Button } from "../../../../components/Button/Button.tsx";
import { useCreateStyle } from "../hooks/useCreateStyle.ts";

export const NewStyleButton = () => {
  const { createStyle, isCreateStyleLoading } = useCreateStyle();

  return (
    <Button
      className="group"
      size="md"
      onClick={() => createStyle()}
      loading={isCreateStyleLoading}
    >
      New model
    </Button>
  );
};
