import classNames from "classnames";
import type { ReactNode } from "react";
import { Badge } from "../../../../../components/Badge/Badge.tsx";
import { Icon, type IconName } from "../../../../../components/Icon/Icon.tsx";
import { Info } from "../../../components/Info.tsx";

export const SettingsSectionWrapper = ({
  name,
  infoContent,
  content,
  rightActionButton,
  className,
  iconName,
  inline = false,
  isBeta = false,
}: {
  name: string;
  infoContent?: ReactNode;
  content: ReactNode;
  className?: string;
  rightActionButton?: ReactNode;
  iconName?: IconName;
  inline?: boolean;
  isBeta?: boolean;
}) => (
  <SettingsSectionLayout className={className}>
    <div
      className={classNames(
        inline ? "flex-row items-center justify-between" : "flex-col gap-300",
      )}
    >
      <div className="flex-row items-center justify-between">
        <div className="flex-row gap-100 items-center">
          {iconName && <Icon size="sm" name={iconName} />}
          <span className="label-md-semibold text-primary first-letter:uppercase">
            {name}
          </span>
          {infoContent !== undefined && <Info content={infoContent} />}
          {isBeta && <Badge size="sm" label="Beta" color="emphasisSecondary" />}
        </div>
        {rightActionButton}
      </div>
      {content}
    </div>
  </SettingsSectionLayout>
);

export const SettingsSectionLayout = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => (
  <div
    className={classNames("flex-col w-full px-400 py-300 border-b", className)}
  >
    {children}
  </div>
);
