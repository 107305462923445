import { useState } from "react";
import { Button } from "../../../../components/Button/Button.tsx";
import { ExtraMenu } from "../components/ExtraMenu.tsx";
import type { Board } from "../types.ts";
import { BoardDeletionDialog } from "./BoardDeletionDialog.tsx";
import { BoardRenamingDialog } from "./BoardRenamingDialog.tsx";

export const BoardExtraMenu = ({
  board,
  className,
}: {
  board: Board;
  className: string;
}) => {
  const [deletionMenuOpen, setDeletionMenuOpen] = useState(false);
  const [renamingMenuOpen, setRenamingMenuOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <ExtraMenu
      className={className}
      isOpen={isPopoverOpen}
      onOpenChange={setIsPopoverOpen}
      dialog={
        <>
          <BoardDeletionDialog
            board={board}
            onDialogChange={setDeletionMenuOpen}
            dialogOpen={deletionMenuOpen}
            onCancel={() => setDeletionMenuOpen(false)}
          />
          <BoardRenamingDialog
            board={board}
            onDialogChange={setRenamingMenuOpen}
            dialogOpen={renamingMenuOpen}
            onCancel={() => setRenamingMenuOpen(false)}
          />
        </>
      }
      popoverContent={
        <div className="flex-col text-primary">
          <Button
            variant="tertiary"
            size="md"
            iconName="Pen"
            onClick={() => {
              setIsPopoverOpen(false);
              setRenamingMenuOpen(true);
            }}
          >
            Rename
          </Button>
          <Button
            variant="tertiary"
            iconName="Trash"
            size="md"
            onClick={() => {
              setIsPopoverOpen(false);
              setDeletionMenuOpen(true);
            }}
          >
            Delete
          </Button>
        </div>
      }
    />
  );
};
