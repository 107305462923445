import {
  Button,
  type ButtonVariant,
} from "../../../components/Button/Button.tsx";
import { useDownload } from "../../../hooks/useDownload.ts";
import { useWorkspace } from "../../../hooks/useWorkspace.ts";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import { upgradeDialogStore } from "../../../singletons/UpgradeDialog/upgradeDialogStore.ts";
import { notifier } from "../../../utils/notifier.ts";
import { trackEvent } from "../../../utils/trackEvent.ts";
import { RemainingCreditMessage } from "../../components/CreditMessage.tsx";
import { useCreateTopUpCheckoutUrl } from "../../Home/HomeIndex/hooks/useCreateTopUpCheckoutUrl.ts";
import { ImageTile } from "../components/ImageTile.tsx";
import type { BoardAsset } from "../types.ts";

// FIXME: this price should be determined by the backend
const WATERMARK_COST = 30;

export const ImagePurchaseMenu = ({
  asset,
  onDownload,
}: {
  asset: BoardAsset;
  onDownload: () => void;
}) => {
  if (asset.content.purchased_at) return null;

  return (
    <div className="flex-col w-[600px] text-primary bg-surface-primary-rest">
      <div className="mx-400 flex-row bg-surface-inverse-rest rounded-200">
        <div className="pt-100 pb-400 flex-col-center w-[50%]">
          <div className="text-white body-md-semibold">Current asset</div>
          <div className="relative">
            <ImageTile
              image={asset.content}
              fit="width"
              srcDimension="medium256"
            />
            <div
              className="absolute inset-0 opacity-25"
              style={{
                backgroundImage:
                  "url('https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/watermark/watermark_45_degrees.png')",
                backgroundRepeat: "repeat",
                backgroundSize: "50px 50px",
              }}
            />
          </div>
        </div>
        <div className="pt-100 pb-400 flex-col-center w-[50%]">
          <div className="text-white body-md-semibold">
            Original - without watermark
          </div>
          <div className="relative">
            <ImageTile
              image={asset.content}
              fit="width"
              srcDimension="medium256"
            />
          </div>
        </div>
      </div>
      <div className="px-400 py-300 flex-col gap-200">
        <div className="flex-col items-end">
          <RemainingCreditMessage cost={WATERMARK_COST} />
        </div>
        <ImagePurchaseActionButtons asset={asset} onDownload={onDownload} />
      </div>
    </div>
  );
};

const ImagePurchaseActionButtons = ({
  asset,
  onDownload,
}: {
  asset: BoardAsset;
  onDownload: () => void;
}) => {
  const { workspace } = useWorkspace();

  switch (workspace.type) {
    case "basic":
      return (
        <div className="w-full flex-row items-center gap-200 justify-end">
          <DownloadWithWatermarkButton asset={asset} onDownload={onDownload} />
          <UpgradeButton
            label="Start free trial"
            variant="primary"
            asset={asset}
          />
        </div>
      );
    case "free_standard":
    case "standard":
      if (workspace.total_credits_remaining_amount < WATERMARK_COST) {
        return (
          <div className="w-full flex-row items-center justify-between">
            <DownloadWithWatermarkButton
              asset={asset}
              onDownload={onDownload}
            />
            <div className="flex-row items-center gap-200">
              <UpgradeButton
                label="Upgrade plan"
                variant="secondary"
                asset={asset}
              />
              <TopUpButton />
            </div>
          </div>
        );
      } else {
        return (
          <DownloadWithoutWatermarkActionButtons
            asset={asset}
            onDownload={onDownload}
          />
        );
      }
    case "free_pro":
    case "pro":
      if (workspace.total_credits_remaining_amount < WATERMARK_COST) {
        return (
          <div className="w-full flex-row items-center justify-between">
            <DownloadWithWatermarkButton
              asset={asset}
              onDownload={onDownload}
            />
            <div className="flex-row items-center gap-200">
              <Button
                variant="secondary"
                onClick={() => {
                  window.open("mailto:contact@pimento.design", "_blank");
                }}
              >
                Contact sales
              </Button>
              <TopUpButton />
            </div>
          </div>
        );
      } else {
        return (
          <DownloadWithoutWatermarkActionButtons
            asset={asset}
            onDownload={onDownload}
          />
        );
      }
  }
};

const DownloadWithoutWatermarkActionButtons = ({
  asset,
  onDownload,
}: {
  asset: BoardAsset;
  onDownload: () => void;
}) => {
  const { workspace } = useWorkspace();
  const [download, isDownloading] = useDownload();

  const purchaseMutation = useAppMutation({
    path: "contents/purchase",
    invalidate: [`assets/${asset.uuid}`, `workspaces/${workspace.uuid}`],
    onSuccess: (response: { data: { uuid: string; download_url: string } }) => {
      notifier.success("Watermark removed successfully!");
      download({
        url: response.data.download_url,
        filename: `${response.data.uuid}.png`,
        onSuccess: onDownload,
        onError: () => {
          notifier.error(
            `Failed to download image from URL ${response.data.download_url}`,
          );
        },
      });
    },
  }).mutation;
  return (
    <div className="flex-row items-center justify-end gap-200">
      <DownloadWithWatermarkButton asset={asset} onDownload={onDownload} />
      <Button
        loading={purchaseMutation.isLoading || isDownloading}
        onClick={() => {
          purchaseMutation.mutate({
            uuid: asset.content.uuid,
            workspace_uuid: workspace.uuid,
          });
          trackEvent("download_content:without_watermark", {
            content_uuid: asset.content.uuid,
          });
        }}
        size="md"
      >
        Download for {WATERMARK_COST} credits
      </Button>
    </div>
  );
};

const UpgradeButton = ({
  label,
  variant,
  asset,
}: {
  label: string;
  variant: ButtonVariant;
  asset: BoardAsset;
}) => (
  <Button
    size="md"
    variant={variant}
    onClick={() => {
      upgradeDialogStore.openDialog();
      trackEvent("download_content:upgrade_plan", {
        content_uuid: asset.content.uuid,
      });
    }}
  >
    {label}
  </Button>
);

const DownloadWithWatermarkButton = ({
  asset,
  onDownload,
}: {
  asset: BoardAsset;
  onDownload: () => void;
}) => {
  const [download, isDownloading] = useDownload();
  return (
    <Button
      variant="tertiary"
      size="md"
      loading={isDownloading}
      onClick={() => {
        download({
          url: asset.content.download_url,
          filename: `${asset.content.uuid}.png`,
          onSuccess: onDownload,
          onError: () => {
            notifier.error(
              `Failed to download image from URL ${asset.content.download_url}`,
            );
          },
        });
        trackEvent("download_content:with_watermark", {
          content_uuid: asset.content.uuid,
        });
      }}
    >
      Download with watermark
    </Button>
  );
};

const TopUpButton = () => {
  const { workspace } = useWorkspace();
  const { createTopUpCheckoutUrl, isCreateTopUpCheckoutUrlLoading } =
    useCreateTopUpCheckoutUrl();
  return (
    <Button
      size="md"
      variant="primary"
      onClick={() => {
        createTopUpCheckoutUrl({ workspaceUuid: workspace.uuid });
      }}
      loading={isCreateTopUpCheckoutUrlLoading}
    >
      Top up credits
    </Button>
  );
};
