import { useUser } from "../../../../hooks/useUser.ts";
import { isUserAllowedTo } from "../../../../types.ts";
import type { Style } from "../types.ts";

export const useUserCanRenameStyle = (style: Style) => {
  const { user } = useUser();

  return (
    !!user &&
    (!style.is_curated || isUserAllowedTo(user, "styles:update-curated-styles"))
  );
};
