import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../components/Button/Button.tsx";
import { PimentoStar } from "../../components/Icon/assets/PimentoStar.tsx";
import { Icon } from "../../components/Icon/Icon.tsx";
import { CDNImage } from "../../components/Image/CDNImage.tsx";
import { useCheckIfStyleUseIsAllowed } from "../../hooks/useCheckIfStyleUseIsAllowed.ts";
import { useWorkspace } from "../../hooks/useWorkspace.ts";
import { BOARDS, GENERATE_TOOL_PATH, HOME, WORKSPACES } from "../../routes.ts";
import { useRetrainStyle } from "../Board/hooks/useRetrainStyle.ts";
import { ErrorMessage } from "../Board/Workspaces/components/ErrorMessage.tsx";
import { useCreateBoard } from "../Home/HomeIndex/hooks/useCreateBoard.ts";
import type { Style } from "../types.ts";

export const StyleDescription = ({ style }: { style: Style }) => {
  const { workspace } = useWorkspace();
  const navigate = useNavigate();
  const { createBoard } = useCreateBoard({
    onSuccess: (uuid) =>
      navigate(
        `${WORKSPACES}/${workspace.uuid}/${BOARDS}/${uuid}/${GENERATE_TOOL_PATH}`,
      ),
  });

  const { retrain, isLoading, isDisabled, isUserAllowed, styleRetrainStatus } =
    useRetrainStyle({
      styleUuid: style.uuid,
    });

  const isStyleUseAllowed = useCheckIfStyleUseIsAllowed(style);

  return (
    <div className="flex-row h-full text-primary">
      <div className="flex-col w-[350px] gap-2000 px-600 py-1000">
        <Link to={`${WORKSPACES}/${workspace.uuid}/${HOME}`}>
          <div className="hover:opacity-70 flex-row px-200 h-1000">
            <Icon name="ChevronLeft" />
            <div className="label-lg-semibold">Back</div>
          </div>
        </Link>
        <div className="flex-col px-200 gap-600">
          <div className="flex-col gap-400">
            {style.training_images.length > 0 ? (
              <CDNImage
                className="h-[128px] w-[128px] rounded-200"
                imageClassName="h-full w-full object-cover object-center"
                src={style.training_images[0].url}
                srcDimension="medium256"
              />
            ) : (
              <div className="flex-col-center h-[128px] w-[128px] rounded-200 bg-surface-emphasis-primary-rest">
                <PimentoStar
                  size={70}
                  className="fill-surface-caution-primary-rest"
                />
              </div>
            )}
            <div className="heading-2xl">{style.name}</div>
          </div>
          <div className="flex-col w-full gap-100">
            <Button
              className="w-fit"
              size="lg"
              onClick={() => {
                createBoard({ styleUuid: style.uuid });
              }}
              disabled={!isStyleUseAllowed}
            >
              Use in new project
            </Button>
            {!isStyleUseAllowed && (
              <ErrorMessage text="Subscribe to a plan to use custom models" />
            )}
          </div>
          {styleRetrainStatus !== "upToDate" && isUserAllowed && (
            <Button
              className="w-fit"
              variant="secondary"
              size="lg"
              disabled={isDisabled}
              onClick={retrain}
              loading={isLoading}
            >
              Retrain
            </Button>
          )}
        </div>
      </div>
      <div className="flex-fill bg-input-surface-rest p-1000 overflow-y-auto">
        <div className="flex-row flex-wrap justify-center gap-200">
          {style.training_images.map((image, index) => (
            /* FIXME: in order to have good quality images without watermark we rely here on the fact that
                                                                                                                        we fetch from the CDN without watermark, even if the size is above the limit in CF */
            <CDNImage
              key={index}
              className="w-[256px] aspect-square"
              imageClassName="h-full w-full object-cover object-center"
              src={image.url}
              srcDimension="large512"
            />
          ))}
        </div>
      </div>
    </div>
  );
};
