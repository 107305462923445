import {
  Button,
  type ButtonVariant,
} from "../../../components/Button/Button.tsx";
import { NavigationButton } from "../../../components/Button/NavigationButton.tsx";
import { Dialog } from "../../../components/Dialog/Dialog.tsx";
import { PimentoStar } from "../../../components/Icon/assets/PimentoStar.tsx";
import { useWorkspace } from "../../../hooks/useWorkspace.ts";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import { WORKSPACES } from "../../../routes.ts";
import { upgradeDialogStore } from "../../../singletons/UpgradeDialog/upgradeDialogStore.ts";
import { trackEvent } from "../../../utils/trackEvent.ts";
import { RemainingCreditMessage } from "../../components/CreditMessage.tsx";
import { useCreateTopUpCheckoutUrl } from "../../Home/HomeIndex/hooks/useCreateTopUpCheckoutUrl.ts";
import type { Style } from "../../types.ts";

// FIXME: this price should be determined by the backend
const TRAINING_COST = 20;

export const StyleTrainingPurchaseDialog = ({
  style,
  isOpen,
  onOpenChange,
  onTrainStyleSuccess,
}: {
  style: Style;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onTrainStyleSuccess?: () => void;
}) => (
  <Dialog
    isOpen={isOpen}
    onOpenChange={(open) => onOpenChange(open)}
    title="Start training your model"
    content={
      isOpen && (
        <StyleTrainingPurchaseMenu
          style={style}
          onTrainStyleSuccess={onTrainStyleSuccess}
        />
      )
    }
  />
);

const StyleTrainingPurchaseMenu = ({
  style,
  onTrainStyleSuccess,
}: {
  style: Style;
  onTrainStyleSuccess?: () => void;
}) => (
  <div className="w-[432px] py-100 flex-col">
    <div className="px-400 py-100 ">
      <div className="py-400 bg-surface-inverse-rest rounded-200 flex-row-center">
        <PimentoStar size={70} className="fill-surface-caution-primary-rest" />
      </div>
    </div>
    <div className="px-400 py-300 flex-col gap-200 items-end">
      <div className="flex-col items-end">
        <RemainingCreditMessage cost={TRAINING_COST} />
      </div>
      <TrainingPurchaseActionButtons
        style={style}
        onTrainStyleSuccess={onTrainStyleSuccess}
      />
    </div>
  </div>
);

const TrainingPurchaseActionButtons = ({
  style,
  onTrainStyleSuccess,
}: {
  style: Style;
  onTrainStyleSuccess?: () => void;
}) => {
  const { workspace } = useWorkspace();

  switch (workspace.type) {
    case "basic":
      return (
        <div className="w-full flex-row items-center gap-200 justify-end">
          <SaveAsDraftButton />
          <UpgradeButton label="Start free trial" variant="primary" />
        </div>
      );
    case "free_standard":
    case "standard":
      if (workspace.total_credits_remaining_amount < TRAINING_COST) {
        return (
          <div className="w-full flex-row items-center justify-between">
            <SaveAsDraftButton />
            <div className="flex-row items-center gap-200">
              <UpgradeButton label="Upgrade plan" variant="secondary" />
              <TopUpButton />
            </div>
          </div>
        );
      } else {
        return (
          <TrainActionButtons
            style={style}
            onTrainStyleSuccess={onTrainStyleSuccess}
          />
        );
      }
    case "free_pro":
    case "pro":
      if (workspace.total_credits_remaining_amount < TRAINING_COST) {
        return (
          <div className="w-full flex-row items-center justify-between">
            <SaveAsDraftButton />
            <div className="flex-row items-center gap-200">
              <Button
                variant="secondary"
                onClick={() => {
                  window.open("mailto:contact@pimento.design", "_blank");
                }}
              >
                Contact sales
              </Button>
              <TopUpButton />
            </div>
          </div>
        );
      } else {
        return (
          <TrainActionButtons
            style={style}
            onTrainStyleSuccess={onTrainStyleSuccess}
          />
        );
      }
  }
};

const SaveAsDraftButton = () => (
  <NavigationButton size="md" variant="tertiary" to={WORKSPACES}>
    Later, save as draft
  </NavigationButton>
);

const UpgradeButton = ({
  label,
  variant,
}: {
  label: string;
  variant: ButtonVariant;
}) => (
  <Button
    size="md"
    variant={variant}
    onClick={() => {
      trackEvent("upgrade:display_popup", {
        reason: "style_training_purchase_button_click",
      });
      upgradeDialogStore.openDialog({ isClosable: true });
    }}
  >
    {label}
  </Button>
);

const TopUpButton = () => {
  const { workspace } = useWorkspace();
  const { createTopUpCheckoutUrl, isCreateTopUpCheckoutUrlLoading } =
    useCreateTopUpCheckoutUrl();
  return (
    <Button
      size="md"
      variant="primary"
      onClick={() => {
        createTopUpCheckoutUrl({ workspaceUuid: workspace.uuid });
      }}
      loading={isCreateTopUpCheckoutUrlLoading}
    >
      Top up credits
    </Button>
  );
};

const TrainActionButtons = ({
  style,
  onTrainStyleSuccess,
}: {
  style: Style;

  onTrainStyleSuccess?: () => void;
}) => {
  const { workspace } = useWorkspace();
  const { mutate: trainStyle, isLoading: isTrainStyleLoading } = useAppMutation(
    {
      path: "styles/train",
      invalidate: [
        `workspaces/${workspace.uuid}/styles`,
        `styles/${style.uuid}`,
      ],
      onSuccess: onTrainStyleSuccess,
    },
  ).mutation;

  return (
    <div className="flex-row items-center gap-200 justify-end">
      <SaveAsDraftButton />
      <Button
        size="md"
        onClick={() => {
          switch (style.type) {
            case null:
              break;
            case "style":
              trainStyle({
                uuid: style.uuid,
                workspace_uuid: workspace.uuid,
              });
              break;
            case "object":
            case "character":
              trainStyle({
                uuid: style.uuid,
                workspace_uuid: workspace.uuid,
                subject_description: style.subject_description ?? "",
              });
              break;
          }
        }}
        loading={isTrainStyleLoading}
      >
        Train your model for {TRAINING_COST} credits
      </Button>
    </div>
  );
};
