import { Link } from "react-router-dom";
import { Icon } from "../../../../../components/Icon/Icon.tsx";
import { CREATE_WORKSPACE, GENERATE_TOOL_PATH } from "../../../../../routes.ts";
import { applyCurrentSearchParamsToPath } from "../../../../../utils/url.ts";
import { useSelectedAsset } from "../../../hooks/useSelectedAsset.ts";
import { lastSelectedToolStore } from "../../stores/lastSelectedToolStore.ts";
import { AssetActions } from "./AssetActions.tsx";

export const EditHeaderContent = () => {
  const { selectedAsset: asset } = useSelectedAsset();
  const { lastCreateTool } = lastSelectedToolStore.useState();

  return (
    <div className="flex-fill flex-row justify-between">
      <Link
        to={applyCurrentSearchParamsToPath(
          lastCreateTool
            ? `../${CREATE_WORKSPACE}/${lastCreateTool}`
            : `../${GENERATE_TOOL_PATH}`,
        )}
        className="flex-row-center gap-100 hover:opacity-80"
      >
        <Icon name="ChevronLeft" />
        <span className="text-primary label-lg-semibold">Back</span>
      </Link>
      {asset && <AssetActions asset={asset} />}
    </div>
  );
};
