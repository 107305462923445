import { useEffect } from "react";
import { z } from "zod";
import { Form } from "../../../../../../../components/Form/Form.tsx";
import { useForm } from "../../../../../../../components/Form/FormContext.ts";
import { useUser } from "../../../../../../../hooks/useUser.ts";
import { useAppQuery } from "../../../../../../../http/useAppQuery.ts";
import { isUserAllowedTo } from "../../../../../../../types.ts";
import type { ModelStyle } from "../../../../../../types.ts";
import { useBoard } from "../../../../../hooks/useBoard.ts";
import { useSelectedStylesGenerationParams } from "../../../../../hooks/useSelectedStylesGenerationParams.ts";
import { GenerationBar } from "../../../../components/GenerationBar/GenerationBar.tsx";
import { GenerationRatioPresetSection } from "../../../../components/SettingsMenu/./GenerationRatioPresetSection.tsx";
import { CollapsableSettingsSectionWrapper } from "../../../../components/SettingsMenu/CollapsableSettingsSectionWrapper.tsx";
import { MlModelArchitectureSection } from "../../../../components/SettingsMenu/MlModelArchitectureSection.tsx";
import { QualityPresetSection } from "../../../../components/SettingsMenu/QualityPresetSection.tsx";
import { SliderSection } from "../../../../components/SettingsMenu/SliderSection.tsx";
import { SwitchSection } from "../../../../components/SettingsMenu/SwitchSection.tsx";
import { TextSection } from "../../../../components/SettingsMenu/TextSection.tsx";
import { SettingsMenuLayout } from "../../../../components/SettingsMenuLayout.tsx";
import { scrollCreateWorkspaceGridToTop } from "../../../utils/scrollCreateWorkspaceGridToTop.ts";
import {
  useGenerateSettings,
  useGenerateSettingsAtom,
} from "../hooks/useGenerateSettings.ts";
import { useImageFromPromptGeneration } from "../hooks/useImageFromPromptGeneration.ts";
import { generatePromptStore } from "../stores/generatePromptStore.ts";

export const GenerateSettings = () => {
  const {
    mutation: generateImageFromPrompt,
    isLoading: isImageFromPromptGenerationLoading,
  } = useImageFromPromptGeneration({
    onSuccess: scrollCreateWorkspaceGridToTop,
  });

  return (
    <Form
      className="flex-col flex-fill"
      schema={zGenerateSettings}
      initialValues={{
        prompt: "",
        selectedStyleUuids: [],
        areSelectedStylesMixable: false,
      }}
      onSubmit={(values) => {
        // FIXME: make the mutation async
        generateImageFromPrompt({
          prompt: values.prompt,
        });
        return Promise.resolve(true);
      }}
    >
      <GenerateSettingsFormContent
        isGenerationLoading={isImageFromPromptGenerationLoading}
      />
    </Form>
  );
};

const zGenerateSettings = z.object({
  // FIXME: use undefined instead of null for unselected selectedStyleUuid
  selectedStyleUuids: z
    .array(z.string().uuid())
    .min(1, { message: "Please select at least a model" }),
  prompt: z.string().min(1, { message: "Please enter a prompt" }),
  areSelectedStylesMixable: z.boolean().refine((val) => val, {
    message: "Please select mixable models",
  }),
});
type StyleGenerateValues = z.input<typeof zGenerateSettings>;

export const GenerateSettingsFormContent = ({
  isGenerationLoading,
}: {
  isGenerationLoading: boolean;
}) => {
  const { board } = useBoard();
  const { generateSettings, defaultGenerateSettings } = useGenerateSettings();
  const {
    selectedStylesGenerationParams,
    areSelectedStylesMixable,
    selectedStyles,
  } = useSelectedStylesGenerationParams();
  const { prompt } = generatePromptStore.useState();
  const { user } = useUser();

  const { setValues, submit, useError } = useForm<StyleGenerateValues>();
  const missingStyleError = useError((v) => v.selectedStyleUuids);
  const stylesNotCompatibleError = useError((v) => v.areSelectedStylesMixable);

  const selectedModelStyleUuid = selectedStyles.find(
    (it) => it.type === "style",
  )?.uuid;
  const { data: selectedModelStyle } = useAppQuery<ModelStyle>({
    queryKey: selectedModelStyleUuid
      ? `styles/${selectedModelStyleUuid}`
      : null,
    enabled: !!selectedModelStyleUuid,
  });

  useEffect(() => {
    setValues({
      selectedStyleUuids: selectedStylesGenerationParams.map((it) => it.uuid),
      areSelectedStylesMixable,
    });
  }, [setValues, selectedStylesGenerationParams, areSelectedStylesMixable]);

  useEffect(() => {
    setValues({ prompt });
  }, [setValues, prompt]);

  return (
    <SettingsMenuLayout
      body={
        <div className="flex-col">
          <GenerationRatioPresetSection
            generationRatio={generateSettings.generation_ratio}
            onGenerationRatioChange={(generationRatio) =>
              useGenerateSettingsAtom.updateGenerateSettings(board.uuid, {
                generation_ratio: generationRatio,
              })
            }
          />
          <CollapsableSettingsSectionWrapper
            name="Advanced"
            content={
              <div className="flex-col">
                <SwitchSection
                  switchName="Style Enhancement"
                  switchInformationSection="Activate it to better catch your style details such as colors ... This is still experimental and can lead to some prompt inconsistencies."
                  value={generateSettings.enable_flux_prompt_enhancement}
                  onChange={(enable) =>
                    useGenerateSettingsAtom.updateGenerateSettings(board.uuid, {
                      enable_flux_prompt_enhancement: enable,
                    })
                  }
                  isBeta
                />
                {selectedModelStyle?.ml_model_architectures.includes("sdxl") &&
                  selectedStylesGenerationParams.length === 1 && (
                    <SwitchSection
                      switchName="Illustration New Model"
                      switchInformationSection="Toggle to use latest Pimento illustration styles"
                      value={generateSettings.ml_model_architecture !== "sdxl"}
                      onChange={(enable) =>
                        useGenerateSettingsAtom.updateGenerateSettings(
                          board.uuid,
                          {
                            ml_model_architecture: enable ? undefined : "sdxl",
                          },
                        )
                      }
                      isBeta
                    />
                  )}
                <TextSection
                  title="Exclude"
                  valuePlaceholder="illustration, wonderful chilli pepper, vivid colors..."
                  value={generateSettings.negative_prompt}
                  onValueChange={(negativePrompt: string) =>
                    useGenerateSettingsAtom.updateGenerateSettings(board.uuid, {
                      negative_prompt: negativePrompt,
                    })
                  }
                />
              </div>
            }
          />
          {user && isUserAllowedTo(user, "mode:debug") && (
            <div className="border-t">
              <CollapsableSettingsSectionWrapper
                name="Debug mode"
                content={
                  <div className="flex-col gap-200">
                    {selectedStylesGenerationParams.length < 2 && (
                      <MlModelArchitectureSection
                        style={selectedModelStyle}
                        mlModelArchitecture={
                          generateSettings.ml_model_architecture
                        }
                        onClickMlModelArchitecture={(mlModelArchitecture) =>
                          useGenerateSettingsAtom.updateGenerateSettings(
                            board.uuid,
                            { ml_model_architecture: mlModelArchitecture },
                          )
                        }
                      />
                    )}
                    <QualityPresetSection
                      value={generateSettings.quality_preset}
                      onChange={(preset) =>
                        useGenerateSettingsAtom.updateGenerateSettings(
                          board.uuid,
                          {
                            quality_preset: preset,
                          },
                        )
                      }
                    />
                    <SliderSection
                      value={Math.round(generateSettings.guidance_scale * 100)}
                      min={0}
                      max={100}
                      onChange={(guidanceScalePercentage) =>
                        useGenerateSettingsAtom.updateGenerateSettings(
                          board.uuid,
                          {
                            guidance_scale: guidanceScalePercentage / 100,
                          },
                        )
                      }
                      sliderName="Guidance scale"
                      defaultValue={
                        defaultGenerateSettings.guidance_scale
                          ? defaultGenerateSettings.guidance_scale * 100
                          : undefined
                      }
                      sliderInformationSection={
                        <div className="flex-col gap-200 body-md-default text-primary">
                          <div>
                            Following range will be applied to the guidance
                            scale:
                          </div>
                          <div>
                            - <span className="body-md-semibold">SDXL</span>: 0%
                            : 5, 100% : 9
                          </div>
                          <div>
                            -{" "}
                            <span className="body-md-semibold">
                              Flux (Photo)
                            </span>
                            : 0% : 2.5, 100% : 4.5
                          </div>
                          <div>
                            -{" "}
                            <span className="body-md-semibold">
                              Flux (Illustration)
                            </span>
                            : 0% : 4, 100% : 6
                          </div>
                        </div>
                      }
                    />
                  </div>
                }
              />
            </div>
          )}
        </div>
      }
      footer={
        <div className="relative flex-row-center">
          <GenerationBar
            isLoading={isGenerationLoading}
            onGenerate={submit}
            prompt={prompt}
            setPrompt={(newPrompt) => generatePromptStore.setPrompt(newPrompt)}
            promptError={useError((v) => v.prompt)}
            autoFocus
            showEnhancer
            extraErrors={[missingStyleError, stylesNotCompatibleError].filter(
              (error): error is string => error !== undefined,
            )}
            infoContent={
              <div className="flex-col gap-100 body-md-default text-primary">
                <div className="body-md-semibold">To get the best results:</div>
                <ul className="pl-400 list-disc">
                  <li>Write in English</li>
                  <li>
                    Respect the following prompt structure: subject, action,
                    background, other style attributes (lightning, camera
                    focus...)
                  </li>
                  <li>
                    Go to{" "}
                    <span className="body-md-semibold">
                      Advanced &gt; Exclude
                    </span>{" "}
                    to remove elements
                  </li>
                </ul>
              </div>
            }
          />
        </div>
      }
    />
  );
};
