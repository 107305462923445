import classNames from "classnames";
import { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { BaseButton } from "../../../../../../components/Button/BaseButton.tsx";
import { ClickableIcon } from "../../../../../../components/Icon/ClickableIcon.tsx";
import {
  Icon,
  type IconName,
} from "../../../../../../components/Icon/Icon.tsx";
import { Popover } from "../../../../../../components/Popover/Popover.tsx";
import { Spinner } from "../../../../../../components/Spinner/Spinner.tsx";
import { Tooltip } from "../../../../../../components/Tooltip/Tooltip.tsx";
import { useAppMutation } from "../../../../../../http/useAppMutation.ts";
import { trackEvent } from "../../../../../../utils/trackEvent.ts";
import type { Style } from "../../../../../types.ts";
import { ImagePicker } from "../../../../components/ImagePicker.tsx";

export const PromptEnhancementButton = ({
  prompt,
  onPromptChange,
  onLoadingChange,
  style,
}: {
  prompt: string;
  onPromptChange: (prompt: string) => void;
  onLoadingChange: (loading: boolean) => void;
  style?: Style;
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [menuState, setMenuState] = useState<
    "ToolList" | "EditWithAI" | "PromptFromImage"
  >("ToolList");

  const onGenerating = (loading: boolean) => {
    onLoadingChange(loading);
    setIsButtonLoading(loading);
    if (loading) setIsMenuOpen(false);
    setMenuState("ToolList");
  };

  return (
    <Popover
      side="right"
      align="end"
      isOpen={isMenuOpen}
      onOpenChange={(open) => {
        setIsMenuOpen(open);
        if (open) trackEvent("prompt_assistant:open", {});
      }}
      content={
        menuState === "ToolList" ? (
          <div className="flex-col">
            <RandomPromptButton
              onPromptChange={onPromptChange}
              style={style}
              onGenerating={onGenerating}
            />
            <EnhancePromptButton
              prompt={prompt}
              onPromptChange={onPromptChange}
              style={style}
              onGenerating={onGenerating}
            />
            <OpenEditWithAIButton
              prompt={prompt}
              onClick={() => setMenuState("EditWithAI")}
            />
            <OpenPromptFromImageButton
              onClick={() => setMenuState("PromptFromImage")}
            />
          </div>
        ) : menuState === "EditWithAI" ? (
          <EditWithAI
            prompt={prompt}
            onClose={() => setMenuState("ToolList")}
            onPromptChange={onPromptChange}
            onGenerating={onGenerating}
          />
        ) : (
          <PromptFromImage
            onPromptChange={onPromptChange}
            onGenerating={onGenerating}
            onClose={() => setMenuState("ToolList")}
          />
        )
      }
      // FIXME: Refactor Popover component to new design system
      className="ml-100 p-200"
    >
      <BaseButton
        className={classNames(
          "w-full h-800 flex-row-center gap-100 border rounded-100 text-primary label-lg-semibold",
          isMenuOpen
            ? "bg-surface-primary-active"
            : "hover:bg-surface-primary-hover",
          isButtonLoading && "bg-input-surface-rest",
        )}
        loading={isButtonLoading}
        loadingContent={<Spinner size="sm" className="text-secondary" />}
      >
        <Icon name="Sparkles" />
        <span className="label-md-semibold text-primary">Enhance prompt</span>
        <Icon
          name="ChevronRight"
          className={classNames(
            "transition-transform",
            isMenuOpen && "-rotate-180",
          )}
        />
      </BaseButton>
    </Popover>
  );
};

const EditWithAI = ({
  prompt,
  onPromptChange,
  onClose,
  onGenerating,
}: {
  prompt: string;
  onPromptChange: (prompt: string) => void;
  onClose: () => void;
  onGenerating: (loading: boolean) => void;
}) => {
  const [instructionText, setInstructionText] = useState("");
  const { mutate: editWithAIMutate } = useAppMutation({
    path: "prompts/edit-with-ai",
    invalidate: [],
    onSuccess: (response: { data: { text: string } }) => {
      onPromptChange(response.data.text);
    },
    onSettled: () => onGenerating(false),
  }).mutation;

  const generate = () => {
    if (instructionText) {
      onGenerating(true);
      editWithAIMutate({ text: prompt, edit_instruction: instructionText });
      trackEvent("prompt_assistant:generate", {
        type: "edit_with_ai",
        instruction: instructionText,
      });
    }
  };

  return (
    <div className="w-full flex-col min-w-[248px] gap-200">
      <div className="flex-row items-center justify-between">
        <span className="label-md-semibold text-primary">
          Edit prompt with AI
        </span>
        <ClickableIcon
          name="X"
          onClick={onClose}
          size="sm"
          variant="tertiary"
        />
      </div>
      <TextareaAutosize
        placeholder="Replace the dog by a cat, change the hat's color to yellow..."
        minRows={5}
        maxRows={5}
        className="w-full flex-fill px-100 py-200 resize-none border rounded-100 text-primary body-md-default placeholder:text-disabled"
        value={instructionText}
        onChange={(event) => {
          setInstructionText(event.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            if (instructionText) generate();
          }
          e.stopPropagation();
        }}
      />
      <BaseButton
        className="w-full h-800 flex-row-center gap-100 border rounded-100 label-lg-semibold enabled:hover:bg-surface-primary-hover disabled:bg-input-surface-rest disabled:text-disabled"
        disabled={!instructionText}
        onClick={generate}
      >
        Update prompt
      </BaseButton>
    </div>
  );
};

const PromptFromImage = ({
  onPromptChange,
  onGenerating,
  onClose,
}: {
  onClose: () => void;
  onPromptChange: (prompt: string) => void;
  onGenerating: (loading: boolean) => void;
}) => {
  const [imageUuid, setImageUuid] = useState<string>();
  const { mutate: describeMutate } = useAppMutation({
    path: "contents/describe",
    invalidate: [],
    onSuccess: (response: { data: { description: string } }) => {
      onPromptChange(response.data.description);
    },
    onSettled: () => onGenerating(false),
  }).mutation;
  return (
    <div className="flex-col w-[248px] gap-200">
      <div className="flex-row items-center justify-between">
        <span className="text-primary label-lg-semibold">
          Get a prompt from an image
        </span>
        <ClickableIcon
          name="X"
          onClick={onClose}
          size="sm"
          variant="tertiary"
        />
      </div>
      <ImagePicker
        className="h-[248px]"
        onImageUuidChange={setImageUuid}
        imageUuid={imageUuid}
        srcDimension="large512"
      />
      <BaseButton
        className="w-full h-800 flex-row-center gap-100 border rounded-100 label-lg-semibold text-primary enabled:hover:bg-surface-primary-hover disabled:bg-input-surface-rest disabled:text-disabled"
        disabled={!imageUuid}
        onClick={() => {
          onGenerating(true);
          describeMutate({ uuid: imageUuid, with_details: true });
          trackEvent("prompt_assistant:generate", {
            type: "from_image",
          });
        }}
      >
        Use this image as prompt
      </BaseButton>
    </div>
  );
};

const RandomPromptButton = ({
  onPromptChange,
  style,
  onGenerating,
}: {
  onPromptChange: (prompt: string) => void;
  onGenerating: (loading: boolean) => void;
  style?: Style;
}) => {
  const { mutate: enhanceMutate } = useAppMutation({
    path: "prompts/enhance",
    invalidate: [],
    onSuccess: (response: { data: { text: string } }) => {
      onPromptChange(response.data.text);
    },
    onSettled: () => onGenerating(false),
  }).mutation;
  return (
    <EnhancementBaseButton
      title="Random prompt"
      iconName="Dices"
      onClick={() => {
        onGenerating(true);
        enhanceMutate({ text: "", style_uuid: style?.uuid });
        trackEvent("prompt_assistant:generate", { type: "random" });
      }}
    />
  );
};

const EnhancePromptButton = ({
  prompt,
  onPromptChange,
  style,
  onGenerating,
}: {
  prompt: string;
  onPromptChange: (prompt: string) => void;
  onGenerating: (loading: boolean) => void;
  style?: Style;
}) => {
  const { mutate: enhanceMutate } = useAppMutation({
    path: "prompts/enhance",
    invalidate: [],
    onSuccess: (response: { data: { text: string } }) => {
      onPromptChange(response.data.text);
    },
    onSettled: () => onGenerating(false),
  }).mutation;
  return (
    <EnhancementBaseButton
      title="Enhance with details"
      iconName="Maximize"
      onClick={() => {
        onGenerating(true);
        enhanceMutate({ text: prompt, style_uuid: style?.uuid });
        trackEvent("prompt_assistant:generate", { type: "add_details" });
      }}
      isDisabled={prompt.length === 0}
      tooltipContent={prompt.length === 0 ? "Write a prompt first" : undefined}
    />
  );
};

const OpenEditWithAIButton = ({
  prompt,
  onClick,
}: {
  prompt: string;
  onClick: () => void;
}) => (
  <EnhancementBaseButton
    title="Edit prompt with AI"
    iconName="WandSparkles"
    onClick={onClick}
    isDisabled={prompt.length === 0}
    tooltipContent={prompt.length === 0 ? "Write a prompt first" : undefined}
  />
);

const OpenPromptFromImageButton = ({ onClick }: { onClick: () => void }) => (
  <EnhancementBaseButton
    title="Prompt from image"
    iconName="ImagePlus"
    onClick={onClick}
  />
);

const EnhancementBaseButton = ({
  title,
  iconName,
  onClick,
  isDisabled,
  tooltipContent,
}: {
  title: string;
  iconName: IconName;
  onClick: () => void;
  isDisabled?: boolean;
  tooltipContent?: string;
}) => (
  <Tooltip
    content={tooltipContent}
    className="!px-200 !py-100 !bg-surface-inverse-rest text-white"
  >
    <BaseButton
      className="body-md-default text-primary px-200 py-100 flex-row gap-200 enabled:hover:bg-surface-primary-hover disabled:opacity-50 rounded-200"
      onClick={onClick}
      disabled={isDisabled}
    >
      <Icon name={iconName} />
      <span className="flex-row items-center">{title}</span>
    </BaseButton>
  </Tooltip>
);
