import { SelectionDropdown } from "../../../../../components/SelectionDropdown/SelectionDropdown.tsx";
import { GENERATION_RATIOS } from "../../../constants.ts";
import type { GenerationRatio } from "../../../types.ts";
import { SettingsSectionWrapper } from "./SettingsSectionWrapper.tsx";

const generationRatioToIcon: Record<
  GenerationRatio,
  "Square" | "RectangleVertical" | "RectangleHorizontal"
> = {
  r_1_1: "Square",
  r_9_16: "RectangleVertical",
  r_3_4: "RectangleVertical",
  r_4_3: "RectangleHorizontal",
  r_16_9: "RectangleHorizontal",
};

export const GenerationRatioPresetSection = ({
  generationRatio,
  onGenerationRatioChange,
}: {
  generationRatio: GenerationRatio;
  onGenerationRatioChange: (generationRatio: GenerationRatio) => void;
}) => (
  <SettingsSectionWrapper
    name="Ratio"
    iconName="Ratio"
    inline
    content={
      <SelectionDropdown
        size="sm"
        value={generationRatio}
        onChange={onGenerationRatioChange}
        options={GENERATION_RATIOS.map((preset) => ({
          value: preset,
          iconName: generationRatioToIcon[preset],
          label: preset.replace("r_", "").replace("_", ":"),
        }))}
        buttonClassName="w-[72px]"
      />
    }
  />
);
