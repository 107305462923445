import { useState } from "react";
import { BaseButton } from "../../../../../components/Button/BaseButton.tsx";
import { Button } from "../../../../../components/Button/Button.tsx";
import { ClickableIcon } from "../../../../../components/Icon/ClickableIcon.tsx";
import { Icon } from "../../../../../components/Icon/Icon.tsx";
import { CDNImage } from "../../../../../components/Image/CDNImage.tsx";
import { Popover } from "../../../../../components/Popover/Popover.tsx";
import { Slider } from "../../../../../components/Slider/Slider.tsx";
import { Tooltip } from "../../../../../components/Tooltip/Tooltip.tsx";
import { ModelDetails } from "../../../../components/ModelDetails/ModelDetails.tsx";
import { StylePicker } from "../../../../components/StylePicker/StylePicker.tsx";
import { GENERIC_STYLE_UUID } from "../../../../constants.ts";
import type { Style } from "../../../../Home/HomeIndex/types.ts";
import type { StyleType } from "../../../../types.ts";
import {
  STYLE_TYPE_ICON_NAMES,
  STYLE_TYPE_LABELS,
} from "../../../constants.ts";
import { useBoard } from "../../../hooks/useBoard.ts";
import { useRetrainStyle } from "../../../hooks/useRetrainStyle.ts";
import {
  DEFAULT_LORA_SCALE,
  useSelectedStylesGenerationParams,
  useSelectedStylesGenerationParamsStore,
} from "../../../hooks/useSelectedStylesGenerationParams.ts";
import { SettingsSectionWrapper } from "./SettingsSectionWrapper.tsx";

export const StylesSelectionSection = () => (
  <>
    <StyleSpecificSelectionSection styleType="style" />
    <StyleSpecificSelectionSection styleType="character" />
    <StyleSpecificSelectionSection styleType="object" isBeta />
  </>
);

const StyleSpecificSelectionSection = ({
  styleType,
  isBeta = false,
}: {
  styleType: StyleType;
  isBeta?: boolean;
}) => {
  const { board } = useBoard();
  const [isStyleSelectorOpen, setIsStyleSelectorOpen] =
    useState<boolean>(false);
  const [activeStyleUuid, setActiveStyleUuid] = useState<string | undefined>(
    undefined,
  );
  const {
    selectedStyles,
    selectedStylesGenerationParams,
    isMixCompatibleWithCurrentTool,
  } = useSelectedStylesGenerationParams();

  const styleSpecificSelectedStyles = selectedStyles.filter(
    (style) => style.type === styleType,
  );

  const isStyleNotCompatibleWithSelectedStyles =
    ((styleType === "character" || styleType === "object") &&
      (selectedStyles.map((it) => it.type).includes("character") ||
        selectedStyles.map((it) => it.type).includes("object"))) ||
    (styleType === "style" &&
      selectedStyles.map((it) => it.type).includes("style"));

  return (
    <>
      <SettingsSectionWrapper
        name={STYLE_TYPE_LABELS[styleType]}
        iconName={STYLE_TYPE_ICON_NAMES[styleType]}
        rightActionButton={
          <ClickableIcon
            name="Plus"
            size="sm"
            variant="secondary"
            onClick={() => {
              setActiveStyleUuid(undefined);
              setIsStyleSelectorOpen(true);
            }}
            disabled={
              isStyleNotCompatibleWithSelectedStyles ||
              selectedStyles.length > (isMixCompatibleWithCurrentTool ? 1 : 0)
            }
            tooltip={{
              side: "right",
              content: isMixCompatibleWithCurrentTool
                ? selectedStyles.length > 1
                  ? "You can only mix two models"
                  : isStyleNotCompatibleWithSelectedStyles
                  ? "You can only mix a style model with a character or an object model"
                  : "Add a model"
                : selectedStyles.length > 0
                ? "You cannot mix models in this tool"
                : "Add a model",
            }}
          />
        }
        content={
          styleSpecificSelectedStyles.length > 0 && (
            <div className="flex-col w-full gap-300">
              {styleSpecificSelectedStyles.map((style) => (
                <StyleActions
                  key={style.uuid}
                  style={style}
                  onStyleClick={() => {
                    setActiveStyleUuid(style.uuid);
                    setIsStyleSelectorOpen(true);
                  }}
                  onStyleRemove={() => {
                    setActiveStyleUuid(undefined);
                    useSelectedStylesGenerationParamsStore.removeStyleFromSelection(
                      {
                        styleUuid: style.uuid,
                        boardUuid: board.uuid,
                      },
                    );
                  }}
                />
              ))}
            </div>
          )
        }
        isBeta={isBeta}
      />
      <StylePicker
        title={`Select ${STYLE_TYPE_LABELS[styleType]}`}
        dialogOpen={isStyleSelectorOpen}
        onDialogChange={setIsStyleSelectorOpen}
        initialSelectedStyleUuid={activeStyleUuid}
        onStyleSelect={(styleUuid) => {
          if (activeStyleUuid && activeStyleUuid !== styleUuid) {
            useSelectedStylesGenerationParamsStore.removeStyleFromSelection({
              styleUuid: activeStyleUuid,
              boardUuid: board.uuid,
            });
          }
          useSelectedStylesGenerationParamsStore.addStyleToSelection({
            styleUuid,
            boardUuid: board.uuid,
          });
          setIsStyleSelectorOpen(false);
        }}
        styleTypeFilter={styleType}
        selectionButtonName={`Use ${STYLE_TYPE_LABELS[styleType]}`}
        disableNonMixableStyles={
          activeStyleUuid === undefined &&
          selectedStylesGenerationParams.length > 0
        }
      />
    </>
  );
};

const StyleActions = ({
  style,
  onStyleClick,
  onStyleRemove,
}: {
  style: Style;
  onStyleClick: () => void;
  onStyleRemove: () => void;
}) => {
  const { selectedStylesGenerationParams, isMixCompatibleWithCurrentTool } =
    useSelectedStylesGenerationParams();
  const { board } = useBoard();

  const loraScale = selectedStylesGenerationParams.find(
    (it) => it.uuid === style.uuid,
  )?.scale;

  const isModelUsable =
    (isMixCompatibleWithCurrentTool &&
      style.is_mixable &&
      selectedStylesGenerationParams.length > 1) ||
    selectedStylesGenerationParams.length < 2;

  return (
    <div className="flex-col gap-300">
      <StyleSelectionButton
        style={style}
        onClick={onStyleClick}
        onRemove={onStyleRemove}
        isModelUsable={isModelUsable}
      />
      {loraScale !== undefined && (
        <div className="flex-row items-center w-full gap-200">
          <div className="text-primary body-md-default">Intensity</div>
          <Slider
            value={Math.round(loraScale * 100)}
            min={0}
            max={100}
            onChange={(loraScalePercentage) =>
              useSelectedStylesGenerationParamsStore.updateStyleLoraScale({
                styleUuid: style.uuid,
                scale: loraScalePercentage / 100,
                boardUuid: board.uuid,
              })
            }
            markValue={DEFAULT_LORA_SCALE * 100}
            className="flex-fill"
            displayPercentage
          />
        </div>
      )}
    </div>
  );
};

const StyleSelectionButton = ({
  style,
  onClick,
  onRemove,
  isModelUsable,
}: {
  style: Style;
  onClick: () => void;
  onRemove: () => void;
  isModelUsable: boolean;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);
  const { selectedStylesGenerationParams, isMixCompatibleWithCurrentTool } =
    useSelectedStylesGenerationParams();

  const { styleRetrainStatus, isUserAllowed } = useRetrainStyle({
    styleUuid: style.uuid,
  });

  return (
    // FIXME: The design of this component is not final and could be improved
    <div className="flex-row items-center w-full gap-100 ">
      <div
        className="w-full flex-fill"
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
      >
        <div className="flex-row flex-fill items-center h-800 px-100 gap-150 rounded-150 border border-input-border-rest hover:border-input-border-hover text-primary label-md-semibold hover:bg-input-surface-hover bg-input-surface-rest">
          <div className="flex-row-center w-500 h-800">
            {isHovering ? (
              <ClickableIcon
                name="X"
                size="sm"
                variant="tertiary"
                onClick={onRemove}
                tooltip={{ content: "Remove the model" }}
              />
            ) : (
              <div className="h-500 w-500 rounded-100 overflow-clip border-input-border-width">
                {style.thumbnail_url ? (
                  <CDNImage
                    src={style.thumbnail_url}
                    className="w-full h-full"
                    imageClassName="h-full w-full object-cover object-center"
                    srcDimension="thumbnail64"
                  />
                ) : style.uuid === GENERIC_STYLE_UUID ? (
                  <div className="w-full h-full flex-row-center bg-surface-emphasis-primary-rest">
                    <Icon
                      size="sm"
                      name="PimentoStar"
                      className="fill-button-primary-rest"
                    />
                  </div>
                ) : (
                  <div className="flex-col-center h-full w-full bg-surface-secondary-rest" />
                )}
              </div>
            )}
          </div>
          <Tooltip content="Replace the model">
            <BaseButton
              className="flex-row flex-fill items-center"
              onClick={onClick}
            >
              <div className="flex-fill truncate w-[70%]">
                {style.name ? style.name : "Untitled"}
              </div>
              <Icon
                size="sm"
                name="ChevronsUpDown"
                className="fill-button-primary-rest"
              />
            </BaseButton>
          </Tooltip>
        </div>
      </div>
      <Popover
        side="right"
        align="end"
        isOpen={isSettingsMenuOpen}
        onOpenChange={(open) => {
          setIsSettingsMenuOpen(open);
        }}
        content={
          <StyleSettingsMenu
            style={style}
            onClose={() => setIsSettingsMenuOpen(false)}
            onClick={onClick}
            isModelUsable={isModelUsable}
            onRemove={onRemove}
          />
        }
      >
        {/* FIXME: We must improve base icon component to deal with more inputs */}
        <Button variant="tertiary" className="aspect-square p-0" size="sm">
          <Icon
            name={
              !isMixCompatibleWithCurrentTool &&
              selectedStylesGenerationParams.length > 1
                ? "TriangleAlert"
                : isModelUsable
                ? "Settings2"
                : styleRetrainStatus === "retrainable" && isUserAllowed
                ? "RefreshCcw"
                : styleRetrainStatus === "training"
                ? "LoaderCircle"
                : "TriangleAlert"
            }
            size="sm"
            className={
              !isMixCompatibleWithCurrentTool &&
              selectedStylesGenerationParams.length > 1
                ? "stroke-error-rest"
                : isModelUsable || styleRetrainStatus === "training"
                ? "stroke-primary-rest"
                : "stroke-error-rest"
            }
          />
        </Button>
      </Popover>
    </div>
  );
};

const StyleSettingsMenu = ({
  style,
  onClose,
  isModelUsable,
  onClick,
  onRemove,
}: {
  style: Style;
  onClose: () => void;
  isModelUsable: boolean;
  onClick?: () => void;
  onRemove?: () => void;
}) => {
  const { selectedStylesGenerationParams, isMixCompatibleWithCurrentTool } =
    useSelectedStylesGenerationParams();

  const {
    retrain,
    isLoading,
    isDisabled: isRetrainDisabled,
    isUserAllowed,
    styleRetrainStatus,
  } = useRetrainStyle({ styleUuid: style.uuid });

  return (
    <div className="flex-col w-[400px] max-h-[100vh]">
      <div className="flex-row items-center w-full justify-between p-200">
        {/* FIXME: The current implementation is a simplification of the current design */}
        <div className="flex-row w-[160px] items-center h-800 px-100 gap-150 rounded-150 text-primary label-md-semibold">
          <div className="flex-row-center w-500 h-800">
            <div className="h-500 w-500 rounded-100 overflow-clip border-input-border-width">
              {style.thumbnail_url ? (
                <CDNImage
                  src={style.thumbnail_url}
                  className="w-full h-full"
                  imageClassName="h-full w-full object-cover object-center"
                  srcDimension="thumbnail64"
                />
              ) : style.uuid === GENERIC_STYLE_UUID ? (
                <div className="w-full h-full flex-row-center bg-surface-emphasis-primary-rest">
                  <Icon
                    size="sm"
                    name="PimentoStar"
                    className="fill-button-primary-rest"
                  />
                </div>
              ) : (
                <div className="flex-col-center h-full w-full bg-surface-secondary-rest" />
              )}
            </div>
          </div>
          <div className="flex-fill truncate w-[70%]">
            {style.name ? style.name : "Untitled"}
          </div>
        </div>
        <ClickableIcon
          name="X"
          size="sm"
          variant="tertiary"
          onClick={onClose}
        />
      </div>
      {isModelUsable ? (
        <div className="flex-fill p-300 overflow-auto">
          <ModelDetails styleUuid={style.uuid} />
        </div>
      ) : !isMixCompatibleWithCurrentTool &&
        selectedStylesGenerationParams.length > 1 ? (
        <div className="p-300 w-full">
          <div className="flex-col-center p-300 gap-300 bg-primary rounded-300">
            <Icon name="TriangleAlert" size="sm" />
            <div className="label-sm-semibold text-primary">
              Multiple model not available with this tool
            </div>
            <div className="label-sm-default text-secondary text-center">
              Unselect one of the selected model
            </div>
            <Button variant="secondary" size="sm" onClick={onRemove}>
              Unselect this model
            </Button>
          </div>
        </div>
      ) : styleRetrainStatus === "retrainable" && isUserAllowed ? (
        <div className="p-300 w-full">
          <div className="flex-col-center p-300 gap-300 bg-primary rounded-300">
            <Icon name="RefreshCcw" size="sm" />
            <div className="label-sm-semibold text-primary">
              This model needs to be retrained
            </div>
            <div className="label-sm-default text-secondary">
              Retraining takes around 15 min
            </div>
            <Button
              variant="primary"
              size="sm"
              onClick={retrain}
              disabled={isRetrainDisabled}
              loading={isLoading}
            >
              Retrain model
            </Button>
          </div>
        </div>
      ) : styleRetrainStatus === "training" ? (
        <div className="p-300 w-full">
          <div className="flex-col-center p-300 gap-300 bg-primary rounded-300">
            <Icon name="LoaderCircle" size="sm" />
            <div className="label-sm-semibold text-primary">
              Model is training
            </div>
            <div className="label-sm-default text-secondary text-center">
              You’ll be able to use it in around 15min. We will send you an
              email when it’s ready
            </div>
          </div>
        </div>
      ) : (
        <div className="p-300 w-full">
          <div className="flex-col-center p-300 gap-300 bg-primary rounded-300">
            <Icon name="TriangleAlert" size="sm" />
            <div className="label-sm-semibold text-primary">
              You can't mix this style yet
            </div>
            <div className="label-sm-default text-secondary text-center">
              Select another one
            </div>
            <Button variant="primary" size="sm" onClick={onClick}>
              Change model
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
