import classNames from "classnames";
import type { ReactNode } from "react";
import { Link } from "react-router-dom";
import styles from "./button.module.css";
import type { ButtonSize, ButtonVariant } from "./Button.tsx";

export const NavigationButton = ({
  children,
  to,
  variant = "primary",
  size = "md",
  className,
}: {
  to: string;
  variant?: ButtonVariant;
  size?: ButtonSize;
  className?: string;
  children?: ReactNode;
}) => (
  <Link
    to={to}
    className={classNames(styles[variant], styles[size], className)}
  >
    {children}
  </Link>
);
