import classNames from "classnames";
import { type ReactNode, useState } from "react";
import { Button } from "../../../../../components/Button/Button.tsx";
import { BaseClickableIcon } from "../../../../../components/Icon/BaseClickableIcon.tsx";
import { Image } from "../../../../../components/Image/Image.tsx";
import { Popover } from "../../../../../components/Popover/Popover.tsx";
import { Spinner } from "../../../../../components/Spinner/Spinner.tsx";
import { Textarea } from "../../../../../components/Textarea/Textarea.tsx";
import type { Style } from "../../../../types.ts";
import { getSelectedTool } from "../../../utils/getSelectedTool.ts";
import { useOnboardingPopovers } from "../../CreateWorkspace/Tools/GenerateView/hooks/useOnboardingPopovers.ts";
import { ErrorMessage } from "../ErrorMessage.tsx";
import { SettingsSectionWrapper } from "../SettingsMenu/SettingsSectionWrapper.tsx";
import { PromptEnhancementButton } from "./PromptEnhancer/PromptEnhancementButton.tsx";

export const GenerationBar = ({
  prompt,
  setPrompt,
  promptError,
  isLoading,
  isDeactivated,
  onGenerate,
  infoContent,
  extraErrors,
  isPromptLoading,
  onPromptLoadingChange,
  style,
  loadingPromptContent = "Describing image…",
  sectionTitle = "Prompt",
  buttonContent = "Generate",
  placeholder = "An illustration of wonderful chilli pepper basking in the sun",
  blockEmptyPrompt = false,
  autoFocus = false,
  showEnhancer = false,
}: {
  prompt: string;
  promptError?: string;
  setPrompt: (prompt: string) => void;
  isLoading: boolean;
  isPromptLoading?: boolean;
  onPromptLoadingChange?: (loading: boolean) => void;
  loadingPromptContent?: ReactNode;
  onGenerate: () => void;
  style?: Style;
  infoContent?: ReactNode;
  extraErrors?: string[];
  sectionTitle?: string;
  buttonContent?: string;
  isDeactivated?: boolean;
  placeholder?: string;
  blockEmptyPrompt?: boolean;
  autoFocus?: boolean;
  showEnhancer?: boolean;
}) => {
  const [isEnhancementLoading, setIsEnhancementLoading] = useState(false);

  const { isOpen: isOnboardingPopoversOpen, step: onboardingStep } =
    useOnboardingPopovers.useState();
  const tool = getSelectedTool();

  const generationDeactivated =
    (blockEmptyPrompt && !prompt) || isLoading || isDeactivated;
  return (
    <SettingsSectionWrapper
      name={sectionTitle}
      infoContent={infoContent}
      className="border-t border-b-0 pb-400"
      content={
        <div className="w-full flex-col-center gap-200 text-primary">
          <div className="w-full flex-col gap-200">
            <div className="relative w-full flex-col-center">
              <Popover
                side="right"
                arrowDisplayed
                updatePositionStrategy="always"
                isOpen={
                  isOnboardingPopoversOpen &&
                  onboardingStep === "prompt" &&
                  tool === "generate"
                }
                content={
                  <div className="flex-col w-[320px] p-600 gap-600 bg-inverse text-inverse-primary">
                    <div className="flex-col gap-400">
                      <div className="body-xl-semibold">
                        Describe what you want to see on your image
                      </div>
                      <div className="body-lg-default">
                        In AI world, many times, you start with a prompt. The
                        goal is to be as much descriptive as you can.
                      </div>
                    </div>
                    <div className="flex-row items-center justify-between">
                      <div className="body-lg-default text-inverse-secondary">
                        1 of 2
                      </div>
                      <div className="flex-row gap-200">
                        <Button
                          size="md"
                          variant="tertiary"
                          children="Use example"
                          // FIXME: We should create new class name inverse tertiary
                          className="!text-disabled"
                          onClick={() => {
                            setPrompt(
                              "A vibrant red chili pepper, smooth and glossy, hangs suspended in the air. Drops of clear, shimmering water slowly cascade down its curved surface, gleaming in the light. The contrast between the fiery hue of the chili and the cool, transparent droplets creates a captivating visual display, highlighting the fresh and crisp nature of the vegetable.",
                            );
                            useOnboardingPopovers.setStep("generationButton");
                          }}
                        />
                        <Button
                          size="md"
                          variant="inverse-primary"
                          children="Next"
                          onClick={() =>
                            useOnboardingPopovers.setStep("generationButton")
                          }
                        />
                      </div>
                    </div>
                  </div>
                }
              >
                <div className="absolute -right-400" />
              </Popover>
              <Textarea
                placeholder={placeholder}
                size="md"
                minRows={2}
                maxRows={12}
                className="w-full"
                inputClassName={classNames(
                  promptError ? "!border-error-rest" : "",
                  isEnhancementLoading ? "animate-pulse" : "",
                )}
                value={prompt}
                onChange={(value) => {
                  setPrompt(value);
                  if (isOnboardingPopoversOpen && onboardingStep === "prompt") {
                    useOnboardingPopovers.setStep("generationButton");
                  }
                }}
                autoFocus={autoFocus}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    if (!generationDeactivated) {
                      onGenerate();
                      useOnboardingPopovers.setIsOpen(false);
                    }
                  }
                  e.stopPropagation();
                }}
                disabled={isEnhancementLoading}
              />
              {isPromptLoading && onPromptLoadingChange && (
                <>
                  <div className="w-full h-full absolute left-0 rounded-100 bg-input-surface-rest flex-row-center gap-100">
                    <Spinner size="sm" />
                    {loadingPromptContent}
                  </div>
                  <BaseClickableIcon
                    name="X"
                    className="absolute top-0 right-0"
                    onClick={() => onPromptLoadingChange(false)}
                  />
                </>
              )}
            </div>
            {promptError && <ErrorMessage text={promptError} />}
            <div className="flex-row items-center gap-100">
              <Image
                className="h-300 aspect-square rounded-200"
                imageClassName="h-full w-full object-contain object-center"
                src="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/flag/us.png"
              />
              <div className="flex-fill text-secondary body-md-default text-wrap">
                English prompts improve results.
              </div>
            </div>
          </div>
          <div className="w-full flex-col gap-200">
            {showEnhancer && (
              <PromptEnhancementButton
                prompt={prompt}
                onPromptChange={setPrompt}
                onLoadingChange={setIsEnhancementLoading}
                style={style}
              />
            )}
            <div className="relative w-full flex-col-center">
              <Button
                className="w-full"
                disabled={generationDeactivated}
                onClick={() => {
                  onGenerate();
                  if (isOnboardingPopoversOpen) {
                    useOnboardingPopovers.setIsOpen(false);
                  }
                }}
                loading={isLoading}
                // XXX: for analytics with GTM
                id="generation-button"
              >
                {buttonContent}
              </Button>
              <Popover
                side="right"
                arrowDisplayed
                updatePositionStrategy="always"
                isOpen={
                  isOnboardingPopoversOpen &&
                  onboardingStep === "generationButton" &&
                  tool === "generate"
                }
                content={
                  <div className="flex-col w-[320px] p-600 gap-600 bg-inverse text-inverse-primary">
                    <div className="flex-col gap-400">
                      <div className="body-xl-semibold">
                        Click to generate images
                      </div>
                      <div className="body-lg-default">
                        Now it’s time to let the magic happen, once you’ve
                        described what you want to see on your image hit
                        “Generate”.
                      </div>
                    </div>
                    <div className="flex-row items-center justify-between">
                      <div className="body-lg-default text-inverse-secondary">
                        2 of 2
                      </div>
                      <Button
                        size="md"
                        variant="inverse-primary"
                        children="Got it"
                        onClick={() => useOnboardingPopovers.setIsOpen(false)}
                      />
                    </div>
                  </div>
                }
              >
                <div className="absolute -right-400" />
              </Popover>
            </div>
            {extraErrors?.map((error) => (
              <ErrorMessage key={error} text={error} />
            ))}
          </div>
        </div>
      }
    />
  );
};
