import { useMemo } from "react";
import { BaseButton } from "../../../../components/Button/BaseButton.tsx";
import { PimentoStar } from "../../../../components/Icon/assets/PimentoStar.tsx";
import { CDNImage } from "../../../../components/Image/CDNImage.tsx";
import type { Board } from "../types.ts";
import { BoardExtraMenu } from "./BoardExtraMenu.tsx";

export const BoardTile = ({
  board,
  onClick,
  isLoading,
  disabled,
}: {
  board: Board;
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
}) => {
  const lastEditionDateInfo = useMemo(() => {
    const now = new Date();
    const boardEditionDate = new Date(board.edited_at);
    const lastEditionDate = {
      days: Math.floor(
        (now.getTime() - boardEditionDate.getTime()) / (1000 * 60 * 60 * 24),
      ),
      hours: Math.floor(
        (now.getTime() - boardEditionDate.getTime()) / (1000 * 60 * 60),
      ),
      minutes: Math.floor(
        (now.getTime() - boardEditionDate.getTime()) / (1000 * 60),
      ),
    };
    return lastEditionDate.days > 0
      ? `Edited ${lastEditionDate.days} day${
          lastEditionDate.days === 1 ? "" : "s"
        } ago`
      : lastEditionDate.hours > 0
      ? `Edited ${lastEditionDate.hours} hour${
          lastEditionDate.hours === 1 ? "" : "s"
        } ago`
      : `Edited ${lastEditionDate.minutes} minute${
          lastEditionDate.minutes < 2 ? "" : "s"
        } ago`;
  }, [board.edited_at]);

  return (
    <div
      className="group relative flex-col border rounded-200 overflow-clip"
      style={{ width: "270px" }}
    >
      <BaseButton
        className="w-full"
        onClick={onClick}
        loading={isLoading}
        disabled={disabled}
      >
        <div className="flex-col">
          <div className="h-[180px]">
            {board.thumbnails_urls.length > 0 ? (
              <div className="grid grid-cols-3 grid-rows-2 bg-primary">
                {board.thumbnails_urls.map((thumbnail, key) => (
                  <CDNImage
                    key={key}
                    src={thumbnail}
                    className="h-[90px] w-[90px]"
                    imageClassName="h-full w-full object-cover object-center"
                    loading="lazy"
                    srcDimension="medium256"
                  />
                ))}
              </div>
            ) : (
              <div className="h-full w-full rounded-050 overflow-clip">
                <div className="flex-col-center h-full w-full bg-primary">
                  <PimentoStar
                    size={70}
                    className="fill-surface-transparent-inverse-rest"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="flex-col p-400 gap-100 body-lg-semibold">
            {board.name ? (
              <div className="truncate">{board.name}</div>
            ) : (
              <div className="text-secondary">Untitled project</div>
            )}
            <div className="h-400 body-md-default text-secondary">
              {lastEditionDateInfo}
            </div>
          </div>
        </div>
      </BaseButton>
      <div className="absolute right-100 top-100">
        <BoardExtraMenu
          board={board}
          className="invisible group-hover:visible"
        />
      </div>
    </div>
  );
};
