import { useWorkspace } from "../../../../hooks/useWorkspace.ts";
import { useAppMutation } from "../../../../http/useAppMutation.ts";
import { RenamingDialog } from "../components/RenamingDialog.tsx";
import type { Board } from "../types.ts";

export const BoardRenamingDialog = ({
  board,
  dialogOpen,
  onDialogChange,
  onCancel,
}: {
  board: Board;
  dialogOpen: boolean;
  onDialogChange: (dialogChange: boolean) => void;
  onCancel: () => void;
}) => {
  const { workspace } = useWorkspace();
  const { mutate: mutateName, isLoading: isMutateNameLoading } = useAppMutation(
    {
      path: "boards/update",
      invalidate: [
        `workspaces/${workspace.uuid}/boards`,
        `boards/${board.uuid}`,
      ],
      onSuccess: () => {
        onDialogChange(false);
      },
    },
  ).mutation;

  return (
    <RenamingDialog
      initialName={board.name}
      onDialogChange={onDialogChange}
      dialogOpen={dialogOpen}
      onClick={(text) => {
        mutateName({ uuid: board.uuid, name: text });
      }}
      isLoading={isMutateNameLoading}
      onCancel={onCancel}
      title="Rename project"
      placeholder="Untitled project"
    />
  );
};
