import { z } from "zod";
import { createStorageStore } from "../../../../../../../utils/createStore.ts";

const ONBOARDING_STEPS = ["prompt", "generationButton"] as const;

export const useOnboardingPopovers = createStorageStore(
  localStorage,
  "first-generation-indication-v2",
  z.object({ isOpen: z.boolean(), step: z.enum(ONBOARDING_STEPS) }),
  { isOpen: true, step: "prompt" },
  ({ setter }) => ({
    setIsOpen: setter("isOpen"),
    setStep: setter("step"),
  }),
);
