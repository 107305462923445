import type { IconName } from "../../components/Icon/Icon.tsx";
import {
  BRIEF_TOOL,
  FILL_TOOL,
  GENERATE_TOOL,
  TRANSFER_TOOL,
  UPSCALE_TOOL,
  VARIATION_TOOL,
} from "../../routes.ts";
import type { StyleType } from "../types.ts";
import type { ImageGenerationModelType, Tool } from "./types.ts";

export const FAVORITE_TAG_NAME = "__favorite__";

export const IMAGE_GENERATION_MODEL_TYPE = [
  "text_to_image",
  "image_to_image",
  "upscaler",
  "generative_fill",
  "style_transfer",
  "brief_to_image",
] as const;

export const GENERATION_RATIOS = [
  "r_1_1",
  "r_9_16",
  "r_3_4",
  "r_4_3",
  "r_16_9",
] as const;

export const NODE_STATUS = [
  "running",
  "succeeded",
  "failed",
  "not_running",
] as const;

export const QUALITY_PRESETS = ["fast", "high"] as const;

export const ML_MODEL_ARCHITECTURES = ["sdxl", "flux"] as const;

export const CREATE_TOOLS = [GENERATE_TOOL, TRANSFER_TOOL, BRIEF_TOOL] as const;

export const EDIT_TOOLS = [VARIATION_TOOL, FILL_TOOL, UPSCALE_TOOL] as const;

export const TOOLS = [...CREATE_TOOLS, ...EDIT_TOOLS] as const;

// XXX: Those tool names are used for tracking purposes (mixpanel `generation_type` property),
// and match the ones defined in the backend.
export const TOOL_TRACKING_NAMES: Record<Tool, string> = {
  generate: "Create",
  transfer: "StyleTransfer",
  variation: "Variation",
  fill: "Fill",
  upscale: "Upscale",
  brief: "Brief",
} as const;

export const STYLE_TYPE_ICON_NAMES: Record<StyleType, IconName> = {
  style: "Droplets",
  character: "PersonStanding",
  object: "Shirt",
} as const;

export const STYLE_TYPE_LABELS: Record<StyleType, string> = {
  style: "style",
  character: "character",
  object: "object",
} as const;

export const TOOL_DISPLAYABLE_NAMES: Record<ImageGenerationModelType, string> =
  {
    text_to_image: "Create",
    style_transfer: "Style transfer",
    brief_to_image: "Brief",
    generative_fill: "Fill",
    image_to_image: "Variation",
    upscaler: "Upscale",
  } as const;
