import { useState } from "react";
import { Button } from "../../../../components/Button/Button.tsx";
import { useUser } from "../../../../hooks/useUser.ts";
import { useWorkspace } from "../../../../hooks/useWorkspace.ts";
import { useAppMutation } from "../../../../http/useAppMutation.ts";
import { isUserAllowedTo } from "../../../../types.ts";
import { ExtraMenu } from "../components/ExtraMenu.tsx";
import { useUserCanDeleteStyle } from "../hooks/useUserCanDeleteStyle.ts";
import { useUserCanOpenStyleTagMenu } from "../hooks/useUserCanOpenStyleTagMenu.ts";
import { useUserCanRenameStyle } from "../hooks/useUserCanRenameStyle.ts";
import type { Style } from "../types.ts";
import { StyleDeletionDialog } from "./StyleDeletionDialog.tsx";
import { StyleRenamingDialog } from "./StyleRenamingDialog.tsx";
import { StyleTagMenu } from "./StyleTagMenu.tsx";

export const StyleExtraMenu = ({
  style,
  className,
}: {
  style: Style;
  className: string;
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [deletionMenuOpen, setDeletionMenuOpen] = useState(false);
  const [renamingMenuOpen, setRenamingMenuOpen] = useState(false);
  const [tagMenuOpen, setTagMenuOpen] = useState(false);
  const userCanOpenStyleTagMenu = useUserCanOpenStyleTagMenu();
  const userCanDeleteStyle = useUserCanDeleteStyle(style);
  const userCanRenameStyle = useUserCanRenameStyle(style);
  const { user } = useUser();
  const { workspace } = useWorkspace();

  const updateStyle = useAppMutation({
    path: "styles/update",
    invalidate: [`workspaces/${workspace.uuid}/styles`],
  }).mutation;

  return (
    <ExtraMenu
      className={className}
      isOpen={isPopoverOpen}
      onOpenChange={setIsPopoverOpen}
      dialog={
        <>
          <StyleDeletionDialog
            style={style}
            onDialogChange={setDeletionMenuOpen}
            dialogOpen={deletionMenuOpen}
            onCancel={() => setDeletionMenuOpen(false)}
          />
          <StyleRenamingDialog
            style={style}
            onDialogChange={setRenamingMenuOpen}
            dialogOpen={renamingMenuOpen}
            onCancel={() => setRenamingMenuOpen(false)}
          />
        </>
      }
      popoverContent={
        tagMenuOpen ? (
          <StyleTagMenu style={style} />
        ) : (
          // FIXME: Add button to be able to transfer a personal style to the library
          <div className="flex-col">
            {userCanOpenStyleTagMenu && style.is_curated && (
              <Button
                variant="tertiary"
                size="md"
                iconName="Tag"
                onClick={() => {
                  setTagMenuOpen(true);
                }}
              >
                Tag
              </Button>
            )}
            {user &&
              isUserAllowedTo(user, "styles:curate") &&
              style.status === "ready" && (
                <Button
                  variant="tertiary"
                  size="md"
                  iconName="Library"
                  onClick={() => {
                    setIsPopoverOpen(false);
                    updateStyle.mutate({
                      uuid: style.uuid,
                      is_curated: !style.is_curated,
                    });
                  }}
                >
                  {!style.is_curated ? "Add to Library" : "Remove from Library"}
                </Button>
              )}
            {userCanRenameStyle && (
              <Button
                variant="tertiary"
                size="md"
                iconName="Pen"
                onClick={() => {
                  setIsPopoverOpen(false);
                  setRenamingMenuOpen(true);
                }}
              >
                Rename
              </Button>
            )}
            {userCanDeleteStyle && (
              <Button
                variant="tertiary"
                size="md"
                iconName="Trash"
                onClick={() => {
                  setIsPopoverOpen(false);
                  setDeletionMenuOpen(true);
                }}
              >
                Delete
              </Button>
            )}
          </div>
        )
      }
    />
  );
};
