export const RectangleVertical = ({
  className,
  size = 16,
}: {
  className?: string;
  size?: number;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={(14 / 22) * size}
    height={size}
    fill="none"
    viewBox="0 0 14 22"
    className={className}
  >
    <rect
      width="20"
      height="13"
      x="0.5"
      y="21"
      rx="1.5"
      transform="rotate(-90 .5 21)"
    />
  </svg>
);
