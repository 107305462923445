import type { ReactNode } from "react";
import { Button } from "../../../../components/Button/Button.tsx";
import { Dialog } from "../../../../components/Dialog/Dialog.tsx";

export const DeletionDialog = ({
  title,
  dialogOpen,
  onDialogChange,
  onCancel,
  isLoading,
  onClick,
  children,
}: {
  title: string;
  dialogOpen: boolean;
  onDialogChange: (dialogChange: boolean) => void;
  onCancel: () => void;
  isLoading?: boolean;
  onClick: () => void;
  children: ReactNode;
}) => (
  <Dialog
    title={title}
    isOpen={dialogOpen}
    onOpenChange={onDialogChange}
    content={
      <div className="flex-col">
        <div className="px-400 py-100">{children}</div>
        <div className="px-400 py-300 flex-row justify-end gap-200">
          <Button
            variant="tertiary"
            size="md"
            onClick={() => onCancel()}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="md"
            loading={isLoading}
            onClick={onClick}
          >
            Delete
          </Button>
        </div>
      </div>
    }
  />
);
