import type { Style as HomeStyle } from "../views/Home/HomeIndex/types.ts";
import type { Style } from "../views/types.ts";
import { useUser } from "./useUser.ts";

// XXX: a free user has not the right to use a custom model older than 7 days
export const useCheckIfStyleUseIsAllowed = (style?: HomeStyle | Style) => {
  const { user } = useUser();

  return (
    !!user?.permissions.includes("styles:generate-with-custom-styles") ||
    !!style?.is_curated
  );
};
